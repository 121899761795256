import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_PAYMENT_HISTORIES } from "./apiPaths";

type Payment = {
  encrypted_payment_no: string;
  payment_type: "1" | "2" | "9";
  payment_type_name: string;
  payment_card_type: "1" | "2" | "3" | "4";
  sales_date: string;
  refund_target_sales_date: string;
  amount: number;
  service_charges_type: "1" | "2" | "3";
  service_id: string;
  service_name: string;
  plan_id?: string;
  plan_name?: string;
  payment_history_display_string?: string;
  payment_history_url?: string;
  card_id: string;
  card_no?: string;
  card_no_last4?: string;
  card_exp?: string;
  card_brand?:
    | "AmericanExpress"
    | "DinersClub"
    | "JCB"
    | "Mastercard"
    | "Visa"
    | "Unknown";
};

export type PaymentHistoriesResponse = {
  payments: Payment[];
};

export const getPaymentHistories = async (): Promise<
  AxiosResponse<PaymentHistoriesResponse>
> => {
  return axios.get(ACCOUNT_API_PAYMENT_HISTORIES, {
    withCredentials: true,
  });
};
