import axios, { type AxiosResponse } from "axios";
import type { RegistrationResponseJSON } from "../../lib/webauthn";
import {
  ACCOUNT_API_PASSKEYS_CREATE,
  ACCOUNT_API_PASSKEYS_CREATION_OPTIONS,
} from "./apiPaths";

export type PasskeyResponse = {
  id: string;
  backup_eligible: boolean;
  created_at: number;
  aaguid: string;
};

/**
 * getCreationOptionsPasskeys
 * 別関数だがPasskeyの登録処理に必要な情報なのでまとめて記載
 * @returns
 */
export const getCreationOptionsPasskeys = async () => {
  return axios.get(ACCOUNT_API_PASSKEYS_CREATION_OPTIONS, {
    withCredentials: true,
  });
};

/**
 * postCreatePasskey
 * @param body RegistrationResponseJSON
 * @returns Promise<AxiosResponse>
 */
export const postCreatePasskey = async (
  body: RegistrationResponseJSON,
): Promise<AxiosResponse<PasskeyResponse>> => {
  return axios.post(ACCOUNT_API_PASSKEYS_CREATE, body, {
    withCredentials: true,
  });
};
