import * as React from "react";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Box";

const BOX_NODES = [
  "header",
  "footer",
  "main",
  "section",
  "article",
  "aside",
  "nav",
  "div",
] as const;

type BoxType = (typeof BOX_NODES)[number];
type BoxElement = React.ElementRef<BoxType>;
type BoxWithRefProps = React.ComponentPropsWithRef<BoxType>;

type BoxProps = BoxWithRefProps &
  React.HTMLAttributes<HTMLElement> & {
    as?: React.ElementType;
    children?: React.ReactNode;
  };

const useBoxState = (props: BoxProps, ref: React.Ref<HTMLElement>) => {
  const { as, ...rest } = props;
  return {
    ...rest,
    ...ref,
  };
};

/**
 * Box component
 */
const Box = React.forwardRef<BoxElement, BoxProps>((props, forwardedRef) => {
  const state = useBoxState(props, forwardedRef);
  const Component = props.as || "div";

  return (
    <Component {...state} ref={forwardedRef}>
      {props.children}
    </Component>
  );
}) as React.ForwardRefExoticComponent<BoxProps>;

Box.displayName = DISPLAY_NAME;

const Root = Box;

export { Box, Root };
export type { BoxProps, BoxElement };
