import * as React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";

import type { FormValidationStatus } from "../../../utils/types";

const DISPLAY_NAME = "Nid.Checkbox";

type InputWithRefProps = React.ComponentPropsWithRef<"input">;

type CheckboxProps = {
  label: React.ReactElement | string;
  name?: string;
  id?: string;
  value?: string | number | readonly string[];
  defaultChecked?: boolean;
  disabled?: boolean;
  className?: string;
  status?: FormValidationStatus;
} & InputWithRefProps;

const useCheckboxState = (props: CheckboxProps) => {
  const { label, className, status, ...rest } = props;
  return { ...rest };
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, forwardedRef) => {
    const nidClasses = clsx(props.className);
    const nidContainerClasses = clsx(
      "nid-checkbox",
      props.disabled && "nid-checkbox-disabled",
      props.status ? `nid-checkbox-${props.status}` : undefined,
    );
    const nidLabelClasses = clsx("nid-typography", "nid-checkbox-label");

    const state = useCheckboxState(props);

    return (
      <fieldset disabled={props.disabled} className={nidContainerClasses}>
        <label>
          <Box as="span" className="nid-checkbox-checkbox">
            <input
              type="checkbox"
              className={nidClasses}
              ref={forwardedRef}
              {...state}
            />
          </Box>
          <Box as="span" className={nidLabelClasses}>
            {props.label}
          </Box>
        </label>
      </fieldset>
    );
  },
) as React.ForwardRefExoticComponent<CheckboxProps>;

Checkbox.displayName = DISPLAY_NAME;
const Root = Checkbox;

export { Checkbox, Root };
export type { CheckboxProps };
export default Checkbox;
