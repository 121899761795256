import axios from "axios";
import { ACCOUNT_API_LOGIN_ID_CHANGE_CONFIRM } from "./apiPaths";

export type ChangeLoginIdConfirmRequest = {
  otp: string;
  login_id: string;
};

export type ChangeLoginIdConfirmErrorResponse = {
  error: string;
};

export const postChangeLoginIdConfirm = async (
  body: ChangeLoginIdConfirmRequest,
) => {
  return axios.post(ACCOUNT_API_LOGIN_ID_CHANGE_CONFIRM, body, {
    withCredentials: true,
  });
};
