import React from "react";

import { clsx } from "clsx";

import type { InputFieldProps } from "../InputField";

const DISPLAY_NAME = "Nid.PinCode";

type PinCodeProps = InputFieldProps;

const usePinCodeState = (props: PinCodeProps) => {
  const { className, status, ...rest } = props;
  return { ...rest };
};

/**
 * PinCode component
 */
const PinCode = React.forwardRef<HTMLInputElement, PinCodeProps>(
  (props, ref) => {
    const nidClasses = clsx(
      "nid-pincode-item",
      props.disabled ? "nid-pincode-item--disabled" : undefined,
      props.status ? `nid-pincode-item--${props.status}` : undefined,
      props.className,
    );
    const state = usePinCodeState(props);

    return (
      <input
        type="text"
        inputMode="numeric"
        autoComplete="one-time-code"
        pattern="\d{1}"
        maxLength={1}
        className={nidClasses}
        ref={ref}
        {...state}
      />
    );
  },
);

PinCode.displayName = DISPLAY_NAME;

const Root = PinCode;

export { PinCode, Root };
export type { PinCodeProps };
export default PinCode;
