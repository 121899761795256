import * as React from "react";

import { clsx } from "clsx";

import { Box } from "../../foundations/Box";
import { SpinnerIcon } from "../Icons/SpinnerIcon/SpinnerIcon";

import { ButtonBase as Button } from "./Button";

type ButtonElement = React.ElementRef<typeof Button>;
type ButtonWithRefProps = React.ComponentPropsWithRef<typeof Button>;

type LoadingButtonProps = ButtonWithRefProps & {
  loading?: boolean;
  loadingPosition?: "start" | "end" | "center";
  loadingIndicator?: React.ReactNode;
};
/**
 * LoadingButton
 */
const LoadingButton = React.forwardRef<ButtonElement, LoadingButtonProps>(
  (props, forwardedRef) => {
    const { loading, loadingIndicator, ...rest } = props;
    const nidClass = `nid-loading-button nid-loading-button-${loading}`;
    const position = props.loadingPosition || "end";
    const loadingIndicatorElement = loadingIndicator || (
      <SpinnerIcon insideTypography={position === "end"} />
    );
    const nidButtonInnerClass = `nid-loading-button-contents nid-loading-button-contents-${position}`;

    return (
      <Button
        className={clsx(nidClass, props.className)}
        ref={forwardedRef}
        {...rest}
      >
        <Box className={nidButtonInnerClass}>
          {position === "start" && loading ? loadingIndicatorElement : null}
          {position === "center"
            ? loading
              ? loadingIndicatorElement
              : props.children
            : props.children}
          {position === "end" && loading ? loadingIndicatorElement : null}
        </Box>
      </Button>
    );
  },
);

LoadingButton.displayName = "Nid.loadingButton";
const Root = LoadingButton;
export { Root, LoadingButton };
export type { LoadingButtonProps };
export default LoadingButton;
