import { clsx } from "clsx";

const DISPLAY_NAME = "Nid.Skeleton";

export type SkeletonProps = {
  loading?: boolean;
  variant?: "text" | "circle" | "rect";
  width?: number | string;
  height?: number | string;
  radius?: number | string;
  bgColor?: string;
};

const Skeleton = ({
  loading = true,
  variant = "rect",
  width = "100%",
  height = "8px",
  radius = "4px",
  bgColor = "rgba(232, 232, 232, 1)",
}: SkeletonProps) => {
  const style = {
    width,
    height,
    borderRadius: radius,
    backgroundColor: bgColor,
  };

  const root = "nid-skeleton";
  const className = `nid-skeleton-${variant}`;

  return loading ? (
    <div className={clsx(root, className)} style={style} />
  ) : null;
};

Skeleton.displayName = DISPLAY_NAME;

const Root = Skeleton;

export { Skeleton, Root };
export default Skeleton;
