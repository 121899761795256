import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_STATUS } from "./apiPaths";

export interface StatusResponse {
  status: "unauthenticated" | "ok";
  account_page_version: number;
  name?: string;
  login_id?: string;
  auth_time?: number;
  last_password_changed?: number;
  is_relying_party_registered?: boolean;
  ppid?: string;
  serial_id: string;
}

export const getLoginStatus: () => Promise<AxiosResponse<StatusResponse>> =
  async () => {
    return axios.get(ACCOUNT_API_STATUS);
  };

export const LoginStatusResourceKey = ACCOUNT_API_STATUS;
