import type React from "react";

import { Box } from "../../../foundations/Box";

export const InfoCircleFillIcon: React.FC<React.ComponentProps<"svg">> = (
  props,
) => {
  const fillColor = props.fill || "#0068BC";
  const strokeColor = props.stroke || "white";
  return (
    <Box className="nid-icon-container">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Icon of InfoCircleFill"
        aria-hidden="true"
        {...props}
      >
        <circle cx="9" cy="9" r="9" fill={fillColor} />
        <path
          d="M8.12305 14.9033H10.1191V7.37695H8.12305V14.9033ZM9.12793 6.22168C9.43783 6.22168 9.70215 6.11458 9.9209 5.90039C10.1396 5.68164 10.249 5.4196 10.249 5.11426C10.249 4.80436 10.1396 4.54232 9.9209 4.32812C9.70215 4.10938 9.43783 4 9.12793 4C8.82259 4 8.55827 4.10938 8.33496 4.32812C8.11165 4.54232 8 4.80436 8 5.11426C8 5.4196 8.11165 5.68164 8.33496 5.90039C8.55827 6.11458 8.82259 6.22168 9.12793 6.22168Z"
          fill={strokeColor}
        />
      </svg>
    </Box>
  );
};
