import type React from "react";

import { Link as RouterLink } from "react-router-dom";
import { Typography2, type Typography2Props } from "../Typography2";

export type Link2Props = Omit<Typography2Props, "color" | "as"> & {
  href?: string;
  to?: string;
  newTab?: boolean;
  type?: string;
};

export const Link2: React.FC<Link2Props> = ({
  href,
  to,
  newTab = false,
  className,
  children,
  ...rest
}) => {
  const linkProps = newTab
    ? { target: "_blank", rel: "noopener noreferrer" }
    : { rel: "noreferrer" };

  const inner = (
    <Typography2 as={"span"} color={"link"} {...rest}>
      {children}
    </Typography2>
  );

  return to ? (
    <RouterLink to={to} className={className} {...linkProps}>
      {inner}
    </RouterLink>
  ) : (
    <a href={href} className={className} {...linkProps}>
      {inner}
    </a>
  );
};
