import type React from "react";

import { Box } from "../../../foundations/Box";

export const DropdownIcon: React.FC<React.ComponentProps<"svg">> = (props) => {
  const fillColor = props.fill || "#333333";
  return (
    <Box className="nid-icon-container-large">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Dropdown"
        role="img"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 9L12 15.4286L17 9H7Z"
          fill={fillColor}
        />
      </svg>
    </Box>
  );
};
