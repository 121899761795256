import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_USERINFO } from "./apiPaths";

export interface UserInfoPatchRequest {
  attributes: {
    LAST_NAME?: string | null;
    FIRST_NAME?: string | null;
    LAST_NAME_KANA?: string | null;
    FIRST_NAME_KANA?: string | null;
    RESIDENCE_COUNTRY_FLAG?: string | null;
    ZIP_CODE?: string | null;
    ADDRESS_CODE?: string | null;
    ADDRESS1?: string | null;
    ADDRESS2?: string | null;
    TEL_JOINT?: string | null;
    OVERSEAS_ZIP_CODE?: string | null;
    OVERSEAS_ADDRESS1?: string | null;
    OVERSEAS_ADDRESS2?: string | null;
    OVERSEAS_ADDRESS3?: string | null;
    OVERSEAS_TEL?: string | null;
    SEX?: string | null;
    BIRTH?: string | null;
    OCCUPATION_NO?: string | null;
    BUSINESS_NO?: string | null;
    JOB_NO?: string | null;
    POSITION_NO?: string | null;
    EMPLOYEES_NO?: string | null;
    INCOME_NO?: string | null;
    NEWS_SUBSCRIPTION?: {
      NEWS_SUBSCRIPTION1?: string;
      NEWS_SUBSCRIPTION2?: string;
      NEWS_SUBSCRIPTION3?: string;
      NEWS_SUBSCRIPTION4?: string;
      NEWS_SUBSCRIPTION5?: string;
    };
    INTEREST?: {
      INTEREST1?: string;
      INTEREST2?: string;
      INTEREST3?: string;
      INTEREST4?: string;
      INTEREST5?: string;
      INTEREST6?: string;
      INTEREST7?: string;
      INTEREST8?: string;
      INTEREST9?: string;
      INTEREST10?: string;
      INTEREST11?: string;
      INTEREST12?: string;
      INTEREST13?: string;
    };
    NIKKEI_MAIL_FLAG?: string;
    THIRDPARTY_MAIL_FLAG?: string;
    NIKKEI_MONITOR_FLAG?: string;
    COMPANY_COUNTRY_FLAG?: string;
    COMPANY_NAME?: string | null;
    COMPANY_TYPE_CODE?: string | null;
    COMPANY_TYPE_LOCATION?: string | null;
    COMPANY_ZIP_CODE?: string | null;
    COMPANY_ADDRESS?: string | null;
    COMPANY_BUSINESS_UNIT?: string | null;
    COMPANY_TEL_JOINT?: string | null;
    COMPANY_OVERSEAS_NAME?: string | null;
    COMPANY_OVERSEAS_ADDRESS?: string | null;
    COMPANY_OVERSEAS_TEL?: string | null;
  };
  dry_run: boolean;
}

export type PatchError = "single" | "complex" | "relation";
export type PatchErrorCode =
  | "invalid_request"
  | "user_not_found"
  | "invalid_attribute"
  | "optimistic_locked";
export type PatchUserInfoErrorResponse = {
  error: PatchErrorCode;
  error_detail?: {
    LAST_NAME?: PatchError;
    FIRST_NAME?: PatchError;
    LAST_NAME_KANA?: PatchError;
    FIRST_NAME_KANA?: PatchError;
    RESIDENCE_COUNTRY_NAME?: PatchError;
    RESIDENCE_COUNTRY_CODE?: PatchError;
    RESIDENCE_COUNTRY_FLAG?: PatchError;
    ZIP_CODE?: PatchError;
    ADDRESS_CODE?: PatchError;
    ADDRESS_CODE_MEI?: PatchError;
    ADDRESS1?: PatchError;
    ADDRESS2?: PatchError;
    TEL_JOINT?: PatchError;
    OVERSEAS_ZIP_CODE?: PatchError;
    OVERSEAS_ADDRESS1?: PatchError;
    OVERSEAS_ADDRESS2?: PatchError;
    OVERSEAS_ADDRESS3?: PatchError;
    OVERSEAS_TEL?: PatchError;
    SEX?: PatchError;
    BIRTH?: PatchError;
    OCCUPATION_NO?: PatchError;
    BUSINESS_NO?: PatchError;
    JOB_NO?: PatchError;
    POSITION_NO?: PatchError;
    EMPLOYEES_NO?: PatchError;
    INCOME_NO?: PatchError;
    NEWS_SUBSCRIPTION1?: PatchError;
    NEWS_SUBSCRIPTION2?: PatchError;
    NEWS_SUBSCRIPTION3?: PatchError;
    NEWS_SUBSCRIPTION4?: PatchError;
    NEWS_SUBSCRIPTION5?: PatchError;
    INTEREST1?: PatchError;
    INTEREST2?: PatchError;
    INTEREST3?: PatchError;
    INTEREST4?: PatchError;
    INTEREST5?: PatchError;
    INTEREST6?: PatchError;
    INTEREST7?: PatchError;
    INTEREST8?: PatchError;
    INTEREST9?: PatchError;
    INTEREST10?: PatchError;
    INTEREST11?: PatchError;
    INTEREST12?: PatchError;
    INTEREST13?: PatchError;
    NIKKEI_MAIL_FLAG?: PatchError;
    THIRDPARTY_MAIL_FLAG?: PatchError;
    NIKKEI_MONITOR_FLAG?: PatchError;
    COMPANY_COUNTRY_FLAG?: PatchError;
    COMPANY_NAME?: PatchError;
    COMPANY_NUMBER?: PatchError;
    COMPANY_TYPE_CODE?: PatchError;
    COMPANY_TYPE_LOCATION?: PatchError;
    COMPANY_ZIP_CODE?: PatchError;
    COMPANY_ADDRESS?: PatchError;
    COMPANY_BUSINESS_UNIT?: PatchError;
    COMPANY_TEL_JOINT?: PatchError;
    COMPANY_OVERSEAS_NAME?: PatchError;
    COMPANY_OVERSEAS_ADDRESS?: PatchError;
    COMPANY_OVERSEAS_TEL?: PatchError;
  };
};

export const patchUserInfo: (
  body: UserInfoPatchRequest,
  ifMatch: string,
) => Promise<AxiosResponse> = async (
  body: UserInfoPatchRequest,
  ifMatch: string,
) => {
  return axios.patch<Response>(ACCOUNT_API_USERINFO, body, {
    withCredentials: true,
    headers: {
      "if-match": ifMatch,
    },
  });
};
