import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_MFA_DISABLE } from "./apiPaths";

export type PostMfaDisableErrorResponse = "invalid_request";

export type MfaDisableErrorResponse = {
  error: PostMfaDisableErrorResponse;
};

export const postMfaDisable = (): Promise<AxiosResponse> => {
  return axios.post(ACCOUNT_API_MFA_DISABLE, undefined, {
    withCredentials: true,
  });
};
