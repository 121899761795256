import { getDate, getMonth, getYear, parse } from "date-fns";

export type NidDate = {
  year: number;
  month: number;
  date: number;
};

export const parseToNidDate = (dateStr: string): NidDate | undefined => {
  const date = parse(dateStr, "yyyyMMdd", new Date());
  const nidDate: NidDate = {
    year: getYear(date),
    month: getMonth(date) + 1,
    date: getDate(date),
  };

  if (nidDateToString(nidDate) !== dateStr) {
    return undefined;
  }

  return nidDate;
};

export const nidDateToString = (date: NidDate): string => {
  return `${date.year.toString().padStart(4, "0")}${date.month
    .toString()
    .padStart(2, "0")}${date.date.toString().padStart(2, "0")}`;
};
