import useSWR from "swr";
import { getMfaStatus } from "../api/account";

export const useMfaStatus = () => {
  const { data, error, isLoading } = useSWR(
    "/account/mfa/status",
    getMfaStatus,
  );

  if (error) {
    return { status: "error", error, data: undefined } as const;
  }
  if (isLoading || data === undefined) {
    return { status: "loading", data: undefined } as const;
  }

  return { status: "ok", data: data.data } as const;
};
