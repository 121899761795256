import axios from "axios";
import { ADS_SEGMENTS } from "./apiPaths";

export type AdsSegmentsResponse = {
  segment_ids: string[];
};

export const getAdsSegments = async (userSerialId: string) => {
  return axios.get<AdsSegmentsResponse>(ADS_SEGMENTS, {
    params: { user_serial_id: userSerialId },
  });
};
