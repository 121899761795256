import type React from "react";
import { type ReactNode, useEffect, useRef } from "react";

import { Box, Typography } from "../..";
import styles from "./Modal.module.css";

interface ModalProps {
  title?: string;
  isOpen: boolean;
  children: ReactNode;
  testId?: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
  testId,
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen && dialogRef.current && dialogRef.current.showModal) {
      dialogRef.current.showModal();
      Object.assign(document.body.style, {
        overflow: "hidden",
      });
    } else if (!isOpen && dialogRef.current && dialogRef.current.close) {
      dialogRef.current.close();
      Object.assign(document.body.style, {
        overflow: "",
      });
    }
  }, [isOpen]);

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDialogElement, MouseEvent>,
  ) => {
    if (event.target === dialogRef.current) {
      dialogRef.current.close();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === "Escape") {
      if (dialogRef.current?.close) {
        dialogRef.current.close();
      }
    }
  };

  return (
    <dialog
      ref={dialogRef}
      onClose={onClose}
      className={styles.dialog}
      data-testid={testId}
      onClick={handleClickOutside}
      onKeyDown={handleKeyDown}
    >
      <Box className={styles.dialogBody}>
        {title && (
          <Typography
            as="h4"
            variant="h4"
            className={"mb-6"}
            data-testid={`${testId}-title`}
            bold
          >
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </dialog>
  );
};

Modal.displayName = "Nid.Modal";
const Root = Modal;
export { Modal, Root };
export default Modal;
