import { useSWRConfig } from "swr";

export const useClearCache = () => {
  const { mutate } = useSWRConfig();
  // clear all
  const clearAllSwrCache = () =>
    mutate(() => true, undefined, { revalidate: false });

  return {
    clearAllSwrCache,
  };
};
