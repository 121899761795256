const halfSpace = "\u0020"; // 半角スペース
const halfWidthRegex = new RegExp(`[!-~${halfSpace}]`, "g");

const fullWidthSpace = "\u3000"; // 全角スペース
const fullWidthRegex = new RegExp(`[！-～${fullWidthSpace}]`, "g");

/**
 * toHalfWidth
 * 全角文字を半角文字に変換
 */
export const toHalfWidth = (val: string): string => {
  return val.replace(fullWidthRegex, (char) => {
    if (char === fullWidthSpace) {
      return halfSpace;
    }
    return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
  });
};
/**
 * toFullWidth
 * 半角文字を全角文字に変換
 */
export const toFullWidth = (val: string): string => {
  // カナ変換
  const fullWidthKana = halfToFullWidthKana(val);
  return fullWidthKana.replace(halfWidthRegex, (char) => {
    if (char === halfSpace) {
      return fullWidthSpace;
    }
    return String.fromCharCode(char.charCodeAt(0) + 0xfee0);
  });
};

/**
 * halfToFullWidthKana
 * 半角カナを全角カナに変換、半角スペースを全角スペースに変換
 */
export const halfToFullWidthKana = (val: string): string => {
  return val.replace(halfKanaRegex, (match) => kanaMapFull[match]);
};

const kanaMapFull: { [key: string]: string } = {
  ｶﾞ: "ガ",
  ｷﾞ: "ギ",
  ｸﾞ: "グ",
  ｹﾞ: "ゲ",
  ｺﾞ: "ゴ",
  ｻﾞ: "ザ",
  ｼﾞ: "ジ",
  ｽﾞ: "ズ",
  ｾﾞ: "ゼ",
  ｿﾞ: "ゾ",
  ﾀﾞ: "ダ",
  ﾁﾞ: "ヂ",
  ﾂﾞ: "ヅ",
  ﾃﾞ: "デ",
  ﾄﾞ: "ド",
  ﾊﾞ: "バ",
  ﾋﾞ: "ビ",
  ﾌﾞ: "ブ",
  ﾍﾞ: "ベ",
  ﾎﾞ: "ボ",
  ﾊﾟ: "パ",
  ﾋﾟ: "ピ",
  ﾌﾟ: "プ",
  ﾍﾟ: "ペ",
  ﾎﾟ: "ポ",
  ｳﾞ: "ヴ",
  ﾜﾞ: "ヷ",
  ｦﾞ: "ヺ",
  ｱ: "ア",
  ｲ: "イ",
  ｳ: "ウ",
  ｴ: "エ",
  ｵ: "オ",
  ｶ: "カ",
  ｷ: "キ",
  ｸ: "ク",
  ｹ: "ケ",
  ｺ: "コ",
  ｻ: "サ",
  ｼ: "シ",
  ｽ: "ス",
  ｾ: "セ",
  ｿ: "ソ",
  ﾀ: "タ",
  ﾁ: "チ",
  ﾂ: "ツ",
  ﾃ: "テ",
  ﾄ: "ト",
  ﾅ: "ナ",
  ﾆ: "ニ",
  ﾇ: "ヌ",
  ﾈ: "ネ",
  ﾉ: "ノ",
  ﾊ: "ハ",
  ﾋ: "ヒ",
  ﾌ: "フ",
  ﾍ: "ヘ",
  ﾎ: "ホ",
  ﾏ: "マ",
  ﾐ: "ミ",
  ﾑ: "ム",
  ﾒ: "メ",
  ﾓ: "モ",
  ﾔ: "ヤ",
  ﾕ: "ユ",
  ﾖ: "ヨ",
  ﾗ: "ラ",
  ﾘ: "リ",
  ﾙ: "ル",
  ﾚ: "レ",
  ﾛ: "ロ",
  ﾜ: "ワ",
  ｦ: "ヲ",
  ﾝ: "ン",
  ｧ: "ァ",
  ｨ: "ィ",
  ｩ: "ゥ",
  ｪ: "ェ",
  ｫ: "ォ",
  ｯ: "ッ",
  ｬ: "ャ",
  ｭ: "ュ",
  ｮ: "ョ",
  ｰ: "ー",
  "･": "・",
  "､": "、",
  "｡": "。",
  "｢": "「",
  "｣": "」",
  " ": "　",
};

const halfKanaRegex = new RegExp(Object.keys(kanaMapFull).join("|"), "g");
