import * as React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";
import { Text } from "../../../foundations/Text";

import { ExclamationIcon } from "../../Icons/ExclamationIcon/ExclamationIcon";

import type { TextProps } from "../../../foundations/Text";
import type { FormValidationStatus } from "../../../utils/types";

const DISPLAY_NAME = "Nid.Form.FormHelperText";

type VariantType = "headline" | "body1" | "body2" | "caption" | string;

type FormHelperTextElement = React.ElementRef<typeof Text>;
type FormHelperTextProps = TextProps & {
  variant?: VariantType;
  status?: FormValidationStatus;
};

const useHelperTextState = (props: FormHelperTextProps) => {
  const { variant, status, className, ...rest } = props;
  return { ...rest };
};

/**
 * FormHelperText component
 */
const FormHelperText = React.forwardRef<
  FormHelperTextElement,
  FormHelperTextProps
>((props, forwardedRef) => {
  const variantClass = "nid-form-helper-text";

  const nidClasses = clsx(
    "nid-form-helper-text",
    variantClass,
    {
      "nid-form-label--color-error": props.status === "error",
    },
    props.className,
  );

  const state = useHelperTextState(props);

  return (
    <Box as="div" className="nid-form-helper-text-container">
      {props.status === "error" && (
        <ExclamationIcon className="nid-form-label--color-error" />
      )}
      <Text as="span" ref={forwardedRef} className={nidClasses} {...state} />
    </Box>
  );
}) as React.ForwardRefExoticComponent<FormHelperTextProps>;

FormHelperText.displayName = DISPLAY_NAME;

const Root = FormHelperText;

export { FormHelperText, Root };
export type { FormHelperTextProps };
export default FormHelperText;
