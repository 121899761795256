import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_START_SIGN_IN } from "./apiPaths";

const url = ACCOUNT_API_START_SIGN_IN;

export type GetStartSignInResponse = {
  state: string;
  code_challenge: string;
};

export const getStartSignIn = (
  redirect: string,
): Promise<AxiosResponse<GetStartSignInResponse>> => {
  return axios.get(url, {
    params: {
      redirect,
    },
  });
};
