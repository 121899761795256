import * as React from "react";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Button";

export type ButtonAs = "button" | "a" | "div" | "input";
export type ButtonType = "button" | "submit" | "reset";

type ButtonElement = React.ElementRef<ButtonAs>;
type ButtonWithRefProps = React.ComponentPropsWithRef<ButtonAs>;

type ButtonProps = ButtonWithRefProps &
  React.HTMLAttributes<HTMLElement> & {
    as?: React.ElementType;
    type?: ButtonType;
    children: React.ReactNode;
  };

const useButtonState = (props: ButtonProps, ref: React.Ref<HTMLElement>) => {
  // as is not a valid prop for the DOM element

  const { as, ...rest } = props;
  return {
    ...rest,
    ...ref,
  };
};

const Button = React.forwardRef<ButtonElement, ButtonProps>(
  (props, forwardedRef) => {
    const Component = props.as || "button";
    const type = ["a", "div"].includes(Component as ButtonAs)
      ? undefined
      : props.type || "submit";

    const state = useButtonState(props, forwardedRef);

    return (
      <Component type={type} ref={forwardedRef} {...state}>
        {props.children}
      </Component>
    );
  },
) as React.ForwardRefExoticComponent<ButtonProps>;

Button.displayName = DISPLAY_NAME;
const Root = Button;
export { Button, Root };
export type { ButtonProps, ButtonElement };
