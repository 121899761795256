import useSWR from "swr";
import { getPasskeys } from "../api/account";

export const usePasskeys = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "/account/passkeys",
    getPasskeys,
  );

  if (error) {
    return { status: "error", error, data: undefined, mutate } as const;
  }
  if (isLoading || data === undefined) {
    return { status: "loading", data: undefined, mutate } as const;
  }

  return { status: "ok", data: data.data, mutate } as const;
};
