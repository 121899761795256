import type React from "react";
import { useEffect } from "react";
import {
  ContentSkeletonLoader,
  PageSkeletonLoader,
} from "../../components/SkeltonLoader";
import { useSignIn } from "../../hooks/useSignIn";
import { useUserStatus } from "../../hooks/useUserStatus";

const requireLogin = (
  maxAge: number | undefined,
  authTime: number | undefined,
  serverTime: number | undefined,
) => {
  if (maxAge === undefined || serverTime === undefined) return false;
  if (authTime === undefined) return true;
  return serverTime - authTime * 1000 > maxAge * 1000;
};

export type LoginWallProviderProps = {
  maxAge?: number;
  children?: React.ReactNode;
  redirectWait?: number;
  loaderType?: "page" | "content";
};

export const LoginWallProvider: React.FC<LoginWallProviderProps> = ({
  children,
  maxAge,
  loaderType = "page",
}) => {
  const { data, serverDate } = useUserStatus();
  const doLogin = requireLogin(maxAge, data?.auth_time, serverDate?.getTime());

  const { startSignIn } = useSignIn();

  const SkeletonLoader =
    loaderType === "page" ? PageSkeletonLoader : ContentSkeletonLoader;

  useEffect(() => {
    const params = (() => {
      if (data?.status === "ok" && doLogin) {
        return {
          maxAge,
          loginHint: `ppid:${data.ppid}`,
          replace: true,
        };
      }
      if (data?.status === "unauthenticated") {
        return { maxAge, replace: true };
      }
      return undefined;
    })();

    if (params !== undefined) {
      startSignIn(params);
    }
  }, [data?.status, doLogin, maxAge]);

  switch (data?.status) {
    case "ok":
      if (doLogin) {
        return <SkeletonLoader />;
      }
      return <>{children}</>;
    case "unauthenticated":
      return <SkeletonLoader />;
    default:
      return <SkeletonLoader />;
  }
};
