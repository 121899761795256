import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_REGISTERED_RELYING_PARTIES } from "./apiPaths";

export interface RegisteredRelyingPartiesResponse {
  client_id: string;
  service_name: string;
  service_link: string | undefined;
  setting_link: string | undefined;
  has_setting_page: boolean;
  b2b_flag: boolean;
  bp_service_detail:
    | {
        service_name: string;
        service_top_url: string | undefined;
      }[]
    | undefined;
  caution: string | undefined;
}

export const getRegisteredRelyingParties: () => Promise<
  AxiosResponse<Array<RegisteredRelyingPartiesResponse>>
> = async () => {
  return axios.get(ACCOUNT_API_REGISTERED_RELYING_PARTIES, {
    withCredentials: true,
  });
};
