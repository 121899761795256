import type React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";
import { Typography } from "../../Typography";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Nid.PasswordStrengthChecker";

type PasswordStrengthCheckerProps = {
  score: 0 | 1 | 2 | 3 | 4 | "empty";
  label: string;
  validationText: string;
};

/**
 * PasswordStrengthChecker component
 * @param score
 * @param strengthScoreList
 * @param label
 * @param validationText
 * @constructor
 */
const PasswordStrengthChecker: React.FC<PasswordStrengthCheckerProps> = ({
  score,
  label,
  validationText,
}) => {
  const nColoredMeters = (score: PasswordStrengthCheckerProps["score"]) => {
    switch (score) {
      case "empty":
        return 0;
      case 0:
        return 1;
      case 1:
        return 1;
      case 2:
        return 3;
      case 3:
        return 5;
      case 4:
        return 8;
    }
  };
  const getColor = (score: PasswordStrengthCheckerProps["score"]) => {
    switch (score) {
      case "empty":
        return "nid-strength-subtle";
      case 0:
        return "nid-strength-weak";
      case 1:
        return "nid-strength-weak";
      case 2:
        return "nid-strength-medium";
      case 3:
        return "nid-strength-success";
      case 4:
        return "nid-strength-strong";
      default:
        return "nid-strength-subtle";
    }
  };

  return (
    <Box className="nid-row">
      <Typography as="p" variant="caption">
        {label}
      </Typography>
      <Box className="nid-strength-text">
        <Typography as="p" variant="caption" bold className={getColor(score)}>
          {score !== "empty" && validationText}
        </Typography>
      </Box>
      <Box className="nid-row">
        {[0, 0, 0, 0, 0, 0, 0, 0].map((_, index) => (
          <Box
            // biome-ignore lint/suspicious/noArrayIndexKey: パスワードレベルメーターは静的な順番のためindexをキーにしても問題ない
            key={index}
            className={clsx(
              "nid-strength",
              index + 1 <= nColoredMeters(score)
                ? getColor(score)
                : "nid-strength-subtle",
            )}
          />
        ))}
      </Box>
    </Box>
  );
};

PasswordStrengthChecker.displayName = DISPLAY_NAME;
export { PasswordStrengthChecker };
export type { PasswordStrengthCheckerProps };
export default PasswordStrengthChecker;
