import axios from "axios";
import { ACCOUNT_API_MASTER_ADDRESS } from "./apiPaths";

export type AddressResponse = {
  zip_code: string;
  address: { label: string; code: string }[];
};

export const getAddress = async (zipCode: string) => {
  return axios.get<AddressResponse>(ACCOUNT_API_MASTER_ADDRESS, {
    params: { zip_code: zipCode },
    withCredentials: true,
  });
};
