import { type AxiosError, isAxiosError } from "axios";
import { useState } from "react";
import {
  type PasskeyResponse,
  getCreationOptionsPasskeys,
  postCreatePasskey,
} from "../api/account/postCreatePasskey";
import {
  parseCreationOptionsFromJSON,
  toRegistrationResponseJson,
} from "../lib/webauthn";
import { useWebAuthn } from "./useWebAuthn";

export type PasskeySuccessResponse = {
  code: "success";
  data: PasskeyResponse;
};

export type PasskeyErrorDetails = {
  message: string;
  code:
    | "user_cancel"
    | "login_required"
    | "server_error"
    | "invalid_request"
    | "unknown_error";
  data?: unknown; // 追加: エラー詳細用のフィールド
};

/**
 * useRegisterPasskey
 * passkeyの登録hooks
 * @returns
 */
export const useRegisterPasskey = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { createPublicKeyCredential } = useWebAuthn();

  /**
   * registerPassKey
   * Passkeyの登録処理
   */
  const registerPassKey = async (): Promise<
    PasskeySuccessResponse | PasskeyErrorDetails
  > => {
    setSubmitting(true);
    try {
      const optionsJSON = await getCreationOptionsPasskeys();
      const publicKey = optionsJSON.data.publicKey;
      const options = parseCreationOptionsFromJSON(publicKey);

      const credential = await createPublicKeyCredential({
        publicKey: options,
      });
      if (!credential) {
        return {
          message: "Credential creation failed",
          code: "invalid_request",
        };
      }
      const registrationData = toRegistrationResponseJson(credential);
      const res = await postCreatePasskey(registrationData);
      return {
        code: "success",
        data: res.data,
      };
    } catch (e) {
      if (
        e instanceof DOMException ||
        e instanceof Object ||
        (e instanceof Error && e.name === "NotAllowedError")
      ) {
        // ユーザーがキャンセル時のエラー 408で返す
        return { message: "Registration canceled", code: "user_cancel" };
      }
      if (isAxiosError(e)) {
        const error = e as AxiosError;
        if (error.status === 403) {
          // 日経IDにログインしていない場合のエラー
          return {
            message: "Please log in to Nikkei ID",
            code: "login_required",
          };
        }
        return {
          message: "Network or server error occurred",
          code: error.status ? "invalid_request" : "server_error",
          data: error.response?.data,
        };
      }
      return { message: "Unknown error occurred", code: "unknown_error" };
    } finally {
      setSubmitting(false);
    }
  };

  return {
    submitting,
    registerPassKey,
  };
};
