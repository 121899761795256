import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_PAYMENTS_CARDS } from "./apiPaths";

type Service = {
  service_id: string;
  service_name: string;
  plan_id?: string;
  plan_name?: string;
  service_charges_type: "1" | "2" | "3";
};

export type Card = {
  card_id: string;
  card_no: string;
  card_no_last4: string;
  card_exp: string;
  card_brand:
    | "AmericanExpress"
    | "DinersClub"
    | "JCB"
    | "Mastercard"
    | "Visa"
    | "Unknown";
  services: Service[];
  unpaid_services: Service[];
  is_valid: boolean;
};

export type PaymentsResponse = {
  cards: Card[];
};

export type PaymentsRequest = {
  service_id?: string;
};

export const getPaymentCards: (
  body: PaymentsRequest,
) => Promise<AxiosResponse<PaymentsResponse>> = async (body) => {
  return axios.get(ACCOUNT_API_PAYMENTS_CARDS, {
    withCredentials: true,
    params: body,
  });
};
