import { Box, SkeletonText } from "nikkei-ui";

import ContentSkeletonLoader from "./ContentSkeletonLoader";
import styles from "./SkeletonLoader.module.css";

export const PageSkeletonLoader: React.FC = () => (
  <>
    <Box className="nid-page-title">
      <Box className={styles.pageLoaderTitle}>
        <Box className={styles.pageLoaderTitleText}>
          <SkeletonText size="small" />
        </Box>
      </Box>
    </Box>

    <ContentSkeletonLoader />
  </>
);

export default PageSkeletonLoader;
