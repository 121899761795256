import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_USERINFO } from "./apiPaths";

export interface UserInfoResponse {
  EMAIL: string;
  ENTRY_DATE: string | undefined;
  UPDATE_DATE: string | undefined;
  LAST_NAME: string | undefined;
  FIRST_NAME: string | undefined;
  LAST_NAME_KANA: string | undefined;
  FIRST_NAME_KANA: string | undefined;
  RESIDENCE_COUNTRY_NAME: string | undefined;
  RESIDENCE_COUNTRY_CODE: string | undefined;
  RESIDENCE_COUNTRY_FLAG: string | undefined;
  ZIP_CODE: string | undefined;
  ADDRESS_CODE: string | undefined;
  ADDRESS_CODE_MEI: string | undefined;
  ADDRESS1: string | undefined;
  ADDRESS2: string | undefined;
  TEL_JOINT: string | undefined;
  OVERSEAS_ZIP_CODE: string | undefined;
  OVERSEAS_ADDRESS1: string | undefined;
  OVERSEAS_ADDRESS2: string | undefined;
  OVERSEAS_ADDRESS3: string | undefined;
  OVERSEAS_TEL: string | undefined;
  SEX: string | undefined;
  BIRTH: string | undefined;
  OCCUPATION_NO: string | undefined;
  BUSINESS_NO: string | undefined;
  JOB_NO: string | undefined;
  POSITION_NO: string | undefined;
  EMPLOYEES_NO: string | undefined;
  INCOME_NO: string | undefined;
  NEWS_SUBSCRIPTION?: {
    NEWS_SUBSCRIPTION1: string | undefined;
    NEWS_SUBSCRIPTION2: string | undefined;
    NEWS_SUBSCRIPTION3: string | undefined;
    NEWS_SUBSCRIPTION4: string | undefined;
    NEWS_SUBSCRIPTION5: string | undefined;
  };
  INTEREST?: {
    INTEREST1: string | undefined;
    INTEREST2: string | undefined;
    INTEREST3: string | undefined;
    INTEREST4: string | undefined;
    INTEREST5: string | undefined;
    INTEREST6: string | undefined;
    INTEREST7: string | undefined;
    INTEREST8: string | undefined;
    INTEREST9: string | undefined;
    INTEREST10: string | undefined;
    INTEREST11: string | undefined;
    INTEREST12: string | undefined;
    INTEREST13: string | undefined;
  };
  NIKKEI_MAIL_FLAG: string | undefined;
  THIRDPARTY_MAIL_FLAG: string | undefined;
  NIKKEI_MONITOR_FLAG: string | undefined;
  COMPANY_COUNTRY_FLAG: string | undefined;
  COMPANY_NAME: string | undefined;
  COMPANY_NUMBER: string | undefined;
  COMPANY_TYPE_CODE: string | undefined;
  COMPANY_TYPE_LOCATION: string | undefined;
  COMPANY_ZIP_CODE: string | undefined;
  COMPANY_ADDRESS: string | undefined;
  COMPANY_BUSINESS_UNIT: string | undefined;
  COMPANY_TEL_JOINT: string | undefined;
  COMPANY_OVERSEAS_NAME: string | undefined;
  COMPANY_OVERSEAS_ADDRESS: string | undefined;
  COMPANY_OVERSEAS_TEL: string | undefined;
}

export const getUserInfo: () => Promise<AxiosResponse<UserInfoResponse>> =
  async () => {
    return axios.get(ACCOUNT_API_USERINFO, {
      withCredentials: true,
    });
  };
