import type React from "react";

export const AppStoreIcon: React.FC = () => {
  return (
    <svg
      width="132"
      height="48"
      viewBox="0 0 132 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="App Store"
      role="img"
    >
      <g clipPath="url(#clip0_9929_13616)">
        <path
          d="M120.061 0H12.004C11.5627 0 11.1267 0 10.6866 0.0024C10.3181 0.0048 9.9526 0.011772 9.58062 0.01764C8.77251 0.0271222 7.96629 0.0980115 7.16898 0.229692C6.37279 0.364239 5.60154 0.617899 4.88132 0.982092C4.16198 1.34937 3.5047 1.8266 2.93332 2.39648C2.35894 2.96476 1.88015 3.62162 1.51536 4.34179C1.14957 5.06053 0.895924 5.83073 0.763192 6.62578C0.629198 7.41982 0.557095 8.22303 0.54753 9.02818C0.536361 9.3961 0.535182 9.76524 0.529297 10.1332V37.8703C0.535182 38.2429 0.536361 38.6039 0.54753 38.9766C0.557098 39.7817 0.629201 40.5849 0.763192 41.3789C0.895557 42.1744 1.14922 42.945 1.51536 43.664C1.87999 44.3819 2.35884 45.0361 2.93332 45.6012C3.50253 46.1736 4.16024 46.6512 4.88132 47.0156C5.60154 47.3808 6.3727 47.636 7.16898 47.7726C7.96643 47.9032 8.77256 47.9741 9.58062 47.9848C9.9526 47.9929 10.3181 47.9976 10.6866 47.9976C11.1267 48 11.5627 48 12.004 48H120.061C120.494 48 120.933 48 121.366 47.9976C121.732 47.9976 122.108 47.9929 122.475 47.9848C123.282 47.9747 124.086 47.9038 124.882 47.7726C125.681 47.635 126.455 47.3799 127.178 47.0156C127.899 46.651 128.556 46.1734 129.125 45.6012C129.698 45.0339 130.178 44.3801 130.547 43.664C130.91 42.9445 131.161 42.1739 131.292 41.3789C131.426 40.5848 131.501 39.7818 131.515 38.9766C131.52 38.6039 131.52 38.2429 131.52 37.8703C131.529 37.4344 131.529 37.0008 131.529 36.5578V11.4434C131.529 11.0039 131.529 10.568 131.52 10.1332C131.52 9.76524 131.52 9.3961 131.515 9.02813C131.501 8.22291 131.426 7.41988 131.292 6.62573C131.161 5.83114 130.91 5.061 130.547 4.34174C129.804 2.89824 128.626 1.72323 127.178 0.981984C126.455 0.618681 125.681 0.365089 124.882 0.229584C124.086 0.0973234 123.282 0.0264093 122.475 0.017472C122.108 0.011616 121.732 0.004584 121.366 0.002244C120.933 0 120.494 0 120.061 0Z"
          fill="#A6A6A6"
        />
        <path
          d="M10.6923 46.9501C10.3256 46.9501 9.96783 46.9454 9.60403 46.9372C8.85039 46.9274 8.09852 46.862 7.35457 46.7415C6.66088 46.6224 5.98887 46.4008 5.36073 46.084C4.73834 45.7699 4.17067 45.358 3.67947 44.8641C3.18116 44.376 2.7663 43.8099 2.45132 43.1883C2.13287 42.5626 1.91248 41.8919 1.79783 41.1997C1.67402 40.4558 1.60704 39.7036 1.59745 38.9497C1.58982 38.6966 1.57983 37.8539 1.57983 37.8539V10.1333C1.57983 10.1333 1.59047 9.30357 1.59751 9.05981C1.60669 8.30703 1.67329 7.55603 1.79672 6.81333C1.91158 6.11915 2.13214 5.44655 2.45076 4.8188C2.76459 4.19758 3.17715 3.63108 3.67246 3.14127C4.16721 2.64679 4.73669 2.23277 5.36014 1.9143C5.98684 1.59856 6.65757 1.37853 7.34986 1.26157C8.09626 1.13985 8.85074 1.07404 9.60699 1.0647L10.6929 1.05005H121.36L122.458 1.06529C123.208 1.07417 123.955 1.13939 124.695 1.2604C125.394 1.37882 126.072 1.60039 126.706 1.91782C127.955 2.55963 128.971 3.57504 129.612 4.82173C129.926 5.44514 130.143 6.11226 130.256 6.80045C130.381 7.54925 130.451 8.30615 130.466 9.0651C130.469 9.40494 130.469 9.76998 130.469 10.1333C130.479 10.5833 130.479 11.0116 130.479 11.4434V36.5578C130.479 36.9938 130.479 37.4192 130.469 37.8481C130.469 38.2383 130.469 38.5958 130.464 38.9637C130.45 39.7091 130.382 40.4525 130.259 41.1879C130.146 41.8852 129.927 42.5611 129.609 43.1919C129.292 43.8068 128.879 44.368 128.387 44.8547C127.895 45.3513 127.326 45.7656 126.702 46.0817C126.07 46.4009 125.394 46.6233 124.695 46.7415C123.951 46.8626 123.199 46.9281 122.446 46.9372C122.093 46.9454 121.724 46.9501 121.366 46.9501L120.061 46.9525L10.6923 46.9501Z"
          fill="black"
        />
        <path
          d="M50.0731 19.8656H45.1972L44.0262 23.3132H41.9609L46.5794 10.5581H48.7252L53.3436 23.3132H51.2431L50.0731 19.8656ZM45.7021 18.2747H49.5672L47.6618 12.6796H47.6085L45.7021 18.2747Z"
          fill="white"
        />
        <path
          d="M63.3179 18.664C63.3179 21.5538 61.7667 23.4105 59.4258 23.4105C58.8328 23.4414 58.2431 23.3052 57.7241 23.0175C57.2051 22.7298 56.7779 22.3021 56.4914 21.7835H56.4471V26.3896H54.5327V14.0137H56.3857V15.5605H56.4209C56.7207 15.0444 57.1551 14.619 57.678 14.3295C58.2009 14.04 58.7928 13.8973 59.3906 13.9165C61.7576 13.9164 63.3179 15.7821 63.3179 18.664ZM61.3502 18.664C61.3502 16.7812 60.3744 15.5434 58.8856 15.5434C57.4229 15.5434 56.4391 16.8073 56.4391 18.664C56.4391 20.5377 57.4229 21.7926 58.8856 21.7926C60.3744 21.7926 61.3502 20.5638 61.3502 18.664Z"
          fill="white"
        />
        <path
          d="M73.5833 18.664C73.5833 21.5538 72.0316 23.4105 69.6907 23.4105C69.0977 23.4414 68.5079 23.3052 67.989 23.0175C67.47 22.7298 67.0428 22.3021 66.7562 21.7835H66.712V26.3896H64.7976V14.0137H66.6506V15.5605H66.6858C66.9856 15.0444 67.42 14.619 67.9429 14.3295C68.4658 14.04 69.0577 13.8973 69.6555 13.9165C72.0225 13.9164 73.5833 15.7821 73.5833 18.664ZM71.6151 18.664C71.6151 16.7812 70.6393 15.5434 69.1504 15.5434C67.6878 15.5434 66.7039 16.8073 66.7039 18.664C66.7039 20.5377 67.6878 21.7926 69.1504 21.7926C70.6393 21.7926 71.6151 20.5638 71.6151 18.664Z"
          fill="white"
        />
        <path
          d="M80.3666 19.7593C80.5085 21.0242 81.7408 21.8547 83.4248 21.8547C85.0384 21.8547 86.1993 21.0242 86.1993 19.8837C86.1993 18.8937 85.4991 18.3009 83.8413 17.8946L82.1834 17.4964C79.8345 16.9306 78.744 15.8353 78.744 14.0578C78.744 11.857 80.6674 10.3455 83.3976 10.3455C86.1017 10.3455 87.9547 11.8571 88.0171 14.0578H86.0846C85.9689 12.7849 84.9136 12.0166 83.3715 12.0166C81.8294 12.0166 80.7741 12.794 80.7741 13.9255C80.7741 14.8272 81.448 15.3579 83.0968 15.7641L84.5062 16.1092C87.1308 16.7281 88.2203 17.7793 88.2203 19.645C88.2203 22.0313 86.315 23.5259 83.283 23.5259C80.4461 23.5259 78.5307 22.0664 78.407 19.7593L80.3666 19.7593Z"
          fill="white"
        />
        <path
          d="M92.3537 11.8131V14.0139H94.1272V15.5255H92.3537V20.6522C92.3537 21.4486 92.7088 21.8198 93.4884 21.8198C93.699 21.8161 93.9092 21.8014 94.1182 21.7757V23.2783C93.7677 23.3436 93.4114 23.3731 93.0549 23.3665C91.1666 23.3665 90.4303 22.6594 90.4303 20.8558V15.5254H89.0742V14.0138H90.4303V11.813L92.3537 11.8131Z"
          fill="white"
        />
        <path
          d="M95.1533 18.664C95.1533 15.738 96.8816 13.8994 99.5766 13.8994C102.281 13.8994 104.001 15.738 104.001 18.664C104.001 21.598 102.29 23.4286 99.5766 23.4286C96.8645 23.4286 95.1533 21.598 95.1533 18.664ZM102.05 18.664C102.05 16.6569 101.128 15.4722 99.5766 15.4722C98.0254 15.4722 97.1029 16.6659 97.1029 18.664C97.1029 20.6792 98.0254 21.8548 99.5766 21.8548C101.128 21.8548 102.05 20.6792 102.05 18.664Z"
          fill="white"
        />
        <path
          d="M105.579 14.0138H107.405V15.5966H107.449C107.573 15.1022 107.863 14.6653 108.272 14.3594C108.68 14.0534 109.182 13.897 109.693 13.9164C109.913 13.9156 110.133 13.9395 110.349 13.9876V15.7731C110.07 15.6882 109.78 15.6493 109.488 15.6577C109.21 15.6465 108.933 15.6954 108.676 15.8011C108.418 15.9067 108.187 16.0667 107.997 16.27C107.808 16.4733 107.665 16.715 107.578 16.9787C107.491 17.2423 107.462 17.5216 107.494 17.7973V23.3132H105.579V14.0138Z"
          fill="white"
        />
        <path
          d="M119.175 20.5819C118.965 21.4607 118.442 22.2336 117.703 22.7571C116.965 23.2806 116.061 23.5192 115.159 23.4286C112.446 23.4286 110.762 21.6161 110.762 18.7081C110.762 15.7912 112.455 13.8994 115.079 13.8994C117.659 13.8994 119.282 15.6668 119.282 18.4865V19.1405H112.695V19.2558C112.664 19.5981 112.707 19.9428 112.821 20.2673C112.934 20.5917 113.116 20.8883 113.353 21.1374C113.59 21.3866 113.878 21.5825 114.197 21.7122C114.516 21.842 114.859 21.9025 115.204 21.8899C115.656 21.9322 116.11 21.8277 116.498 21.592C116.886 21.3563 117.187 21.0021 117.357 20.5819L119.175 20.5819ZM112.704 17.8064H117.366C117.384 17.4986 117.337 17.1907 117.229 16.9019C117.121 16.613 116.955 16.3495 116.74 16.1279C116.525 15.9063 116.267 15.7313 115.981 15.614C115.695 15.4967 115.388 15.4395 115.079 15.4461C114.767 15.4443 114.458 15.504 114.17 15.6219C113.881 15.7397 113.619 15.9134 113.398 16.1328C113.177 16.3523 113.002 16.6131 112.883 16.9004C112.764 17.1876 112.703 17.4955 112.704 17.8064Z"
          fill="white"
        />
        <path
          d="M44.3797 36.9739L44.4591 35.9907C44.8288 36.1096 45.2147 36.1709 45.6032 36.1723C45.9546 36.1723 46.0903 36.1383 46.2607 35.9005C46.6751 35.0356 46.8546 34.0776 46.7814 33.1219C46.7814 32.1047 46.4647 31.8106 45.6602 31.8106C45.4786 31.8106 45.2747 31.8223 45.0479 31.834C44.6149 33.5885 43.8955 35.2601 42.9183 36.7816L41.9775 36.2731C42.8953 34.958 43.567 33.4882 43.9602 31.9348C43.3394 32.0017 42.7229 32.1038 42.1138 32.2406L41.9099 31.2457C42.601 31.1215 43.4278 31.009 44.1982 30.941C44.3565 30.1909 44.4738 29.4327 44.5496 28.6699L45.6602 28.7941C45.5579 29.4832 45.4334 30.1734 45.2747 30.8508H45.7166C47.1557 30.8508 47.8468 31.5621 47.8468 33.0762C47.9092 34.2315 47.6835 35.3842 47.1898 36.4313C47.0598 36.6719 46.8613 36.8689 46.6194 36.9976C46.3775 37.1262 46.1029 37.1807 45.83 37.1543C45.3412 37.1511 44.8544 37.0905 44.3797 36.9739ZM48.0278 30.8848L48.8551 30.3305C50.0023 31.4339 50.6935 32.9256 50.7926 34.5118L49.7049 34.6805C49.6969 33.9677 49.5441 33.2639 49.2559 32.6115C48.9677 31.9592 48.5499 31.3718 48.0278 30.8848Z"
          fill="white"
        />
        <path
          d="M54.5493 37.1321L54.3231 36.1149C54.6063 36.1383 54.9801 36.1489 55.2069 36.1489C57.4499 36.1489 58.2996 35.5173 58.2996 34.5786C58.2996 33.8333 57.8124 33.4044 56.7247 33.4044C55.5388 33.5128 54.4341 34.0516 53.6203 34.9184L52.7476 34.5341C52.5824 33.0541 52.9217 31.5618 53.7108 30.2977L54.7192 30.6246C54.0909 31.4706 53.7857 32.5123 53.8583 33.5625C54.767 32.9236 55.8407 32.5586 56.9516 32.5113C58.4701 32.5113 59.4103 33.2906 59.4103 34.5562C59.4103 36.1605 58.141 37.1437 55.0936 37.1437C54.946 37.1438 54.7309 37.1321 54.5493 37.1321ZM53.4957 29.6309L53.7789 28.636C55.2863 28.8659 56.7746 29.2059 58.232 29.6532L57.96 30.6364C56.5052 30.1682 55.0111 29.8317 53.4957 29.6309Z"
          fill="white"
        />
        <path
          d="M62.6916 36.3527C63.86 35.9693 64.9096 35.292 65.7391 34.3863C65.082 33.8433 64.3724 33.3667 63.6207 32.9637L64.2436 32.1844C65.0015 32.5687 65.722 33.0222 66.3961 33.5391C66.9374 32.7519 67.315 31.8645 67.5067 30.9293H64.6173C63.9534 32.0017 63.1192 32.9595 62.1475 33.7653L61.3201 33.0539C62.7187 31.9035 63.8025 30.4187 64.4704 28.7379L65.5581 28.9981C65.4335 29.3145 65.309 29.6414 65.1614 29.9473H67.9715L68.776 30.3199C68.5808 31.8533 67.9733 33.3056 67.0179 34.5227C66.0624 35.7399 64.7946 36.6768 63.3486 37.234L62.6916 36.3527ZM67.4726 28.4672L68.1072 28.1613C68.4293 28.5914 68.6994 29.0579 68.9117 29.5512L68.1977 29.8793C68.018 29.3794 67.7743 28.9048 67.4726 28.4672ZM68.8888 28.3535L69.5346 28.0488C69.8611 28.4923 70.1385 28.9697 70.362 29.4727L69.648 29.7773C69.4582 29.2718 69.2029 28.7932 68.8888 28.3535Z"
          fill="white"
        />
        <path
          d="M73.8467 37.1883L73.2691 36.273C74.4367 36.0873 75.4928 35.4736 76.2305 34.5521C76.9682 33.6307 77.3344 32.4679 77.2574 31.2914H72.4987V33.5625H71.354V30.3305H74.1981V28.6699H75.3422V30.3305H77.4612L78.5037 30.6246C78.3674 33.8332 77.2914 36.2848 73.8467 37.1883Z"
          fill="white"
        />
        <path
          d="M80.7078 30.4101L81.3424 29.4832C82.3336 29.9349 83.2687 30.5 84.129 31.1671L83.4038 32.1386C82.5783 31.4562 81.673 30.8758 80.7078 30.4101ZM80.9228 35.98C82.3092 35.772 83.6256 35.237 84.7628 34.4194C85.9 33.6019 86.825 32.5254 87.4608 31.2796L88.2083 32.1164C87.5179 33.4028 86.5411 34.5145 85.353 35.3662C84.1649 36.2179 82.797 36.7871 81.3542 37.03L80.9228 35.98Z"
          fill="white"
        />
        <path
          d="M97.4943 36.5226H90.3674V29.7996H97.4943L97.4943 36.5226ZM91.4898 30.839V35.4832H96.3613V30.839H91.4898Z"
          fill="white"
        />
        <path d="M107.698 33.505H99.6873V32.353H107.698V33.505Z" fill="white" />
        <path
          d="M110.423 28.7039H111.545V31.5961C113.036 32.2149 114.482 32.9362 115.873 33.7547L115.238 34.7484C114.06 33.9961 112.825 33.3352 111.545 32.7714V37.132H110.423V28.7039ZM113.085 29.3144L113.754 28.998C114.082 29.4771 114.352 29.9927 114.559 30.5344L113.822 30.8625C113.645 30.3168 113.397 29.7964 113.085 29.3144ZM114.377 28.8281L115.057 28.5117C115.401 28.988 115.687 29.5038 115.907 30.048L115.17 30.3761C114.968 29.8307 114.702 29.311 114.377 28.8281Z"
          fill="white"
        />
        <path
          d="M30.338 24.3608C30.3509 23.3592 30.6177 22.3771 31.1136 21.506C31.6094 20.6348 32.3182 19.9029 33.174 19.3783C32.6304 18.6041 31.9132 17.967 31.0794 17.5175C30.2456 17.0681 29.3181 16.8187 28.3708 16.7891C26.3498 16.5776 24.3906 17.9949 23.3608 17.9949C22.3111 17.9949 20.7255 16.8101 19.0181 16.8451C17.9137 16.8807 16.8374 17.2009 15.894 17.7746C14.9506 18.3483 14.1724 19.1558 13.635 20.1186C11.3075 24.1367 13.0436 30.0419 15.2732 33.2899C16.3887 34.8803 17.6924 36.6568 19.3983 36.5938C21.0676 36.5248 21.691 35.5324 23.706 35.5324C25.7023 35.5324 26.2872 36.5938 28.0277 36.5538C29.8189 36.5248 30.9475 34.9563 32.0238 33.3508C32.8253 32.2176 33.442 30.9651 33.8512 29.6398C32.8105 29.2009 31.9225 28.4663 31.2977 27.5275C30.673 26.5888 30.3392 25.4874 30.338 24.3608Z"
          fill="white"
        />
        <path
          d="M27.0505 14.6531C28.0271 13.484 28.5083 11.9814 28.3918 10.4644C26.8996 10.6206 25.5214 11.3317 24.5315 12.4559C24.0475 13.0051 23.6769 13.644 23.4407 14.3361C23.2045 15.0283 23.1075 15.76 23.1551 16.4896C23.9014 16.4972 24.6397 16.3359 25.3145 16.0178C25.9892 15.6997 26.5828 15.2331 27.0505 14.6531Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9929_13616">
          <rect
            width="131"
            height="48"
            fill="white"
            transform="translate(0.529297)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
