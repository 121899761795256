import type React from "react";

export const QuestionCircleIcon: React.FC<React.ComponentProps<"svg">> = (
  props,
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Icon of QuestionCircle"
    aria-hidden="true"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      fill="#003E70"
    />
    <path
      d="M10.478 14.042H12.298C12.074 12.11 15.14 11.858 15.14 9.814C15.14 7.952 13.656 7 11.626 7C10.128 7 8.896 7.686 8 8.722L9.148 9.772C9.834 9.058 10.534 8.666 11.388 8.666C12.494 8.666 13.166 9.142 13.166 9.996C13.166 11.354 10.184 11.844 10.478 14.042ZM11.402 17.78C12.102 17.78 12.634 17.262 12.634 16.534C12.634 15.806 12.102 15.302 11.402 15.302C10.688 15.302 10.156 15.806 10.156 16.534C10.156 17.262 10.674 17.78 11.402 17.78Z"
      fill="white"
    />
  </svg>
);
