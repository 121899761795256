import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_MFA_SETUP_AUTHENTICATOR_START } from "./apiPaths";

const url = ACCOUNT_API_MFA_SETUP_AUTHENTICATOR_START;

export type MfaSetupAuthenticatorStartResponse = {
  mfa_setup_token: string;
  uri: string;
  authenticator_secret: string;
};

export const postMfaSetupAuthenticatorStart = (): Promise<
  AxiosResponse<MfaSetupAuthenticatorStartResponse>
> => {
  return axios.post(url, undefined, {
    withCredentials: true,
  });
};
