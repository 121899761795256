import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from "react-router-dom";

import { SpinnerIcon } from "../Icons/SpinnerIcon/SpinnerIcon";

import { Typography } from "../Typography";

import type { TypographyProps } from "../Typography";

const DISPLAY_NAME = "Nid.Link";

type LinkProps = TypographyProps & {
  href?: string;
  to?: string;
  loading?: boolean;
  newTab?: boolean;
};

type ReactRouterLinkProps = RouterLinkProps &
  TypographyProps & {
    href?: string;
    loading?: boolean;
    newTab?: boolean;
  };

/**
 * Link component
 */
const Link = (props: LinkProps | ReactRouterLinkProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { href, loading = false, onClick, newTab = false, ...rest } = props;

  const linkProps = newTab
    ? { target: "_blank", rel: "noopener noreferrer" }
    : { rel: "noreferrer" };

  return (
    <Typography
      as={props.to ? RouterLink : "a"}
      color={loading ? "disabled" : "link"}
      href={loading ? undefined : props?.href}
      to={loading ? undefined : props?.to}
      onClick={loading ? () => {} : onClick}
      {...linkProps}
      {...rest}
    >
      {props.children}
      {loading && <SpinnerIcon insideTypography />}
    </Typography>
  );
};

Link.displayName = DISPLAY_NAME;

const Root = Link;

export { Link, Root };
export type { LinkProps };
export default Link;
