import * as React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";

import styles from "./InputField.module.css";

import type {
  FormInputSize,
  FormValidationStatus,
} from "../../../utils/types"; /* DISPLAY_NAME */

/* DISPLAY_NAME */
const DISPLAY_NAME = "Nid.InputField";

type InputElement = React.ElementRef<"input">;
type InputWithRefProps = React.ComponentPropsWithRef<"input">;

type InputProps = InputWithRefProps &
  React.InputHTMLAttributes<InputElement> & {
    startAdornment?: string | React.ComponentType;
    endAdornment?: string | React.ComponentType;
    status?: FormValidationStatus;
    loading?: boolean;
    size?: FormInputSize;
    disabled?: boolean;
    autoCompleteList?: string[];
    onSelectAutoComplete?: (value: string) => void;
    containerClassName?: string;
  };

type InputFieldProps = InputProps & InputWithRefProps;

/**
 * InputField component
 */
const InputField = React.forwardRef<InputElement, InputProps>(
  (
    {
      startAdornment: StartAdornmentComponent,
      endAdornment: EndAdornmentComponent,
      status = undefined,
      loading,
      size,
      disabled,
      className,
      autoCompleteList,
      onSelectAutoComplete,
      containerClassName,
      ...rest
    },
    forwardedRef,
  ) => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>();
    const [inputText, setInputText] = React.useState<string>("");
    // ESCキー、SelectedIndexがある状態でEnterキー、クリックで選択、域外クリックしたときはfalseにする
    const [showAutoComplete, setShowAutoComplete] =
      React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    React.useImperativeHandle(
      forwardedRef,
      () => inputRef.current as InputElement,
    );
    const autoCompleteRef = React.useRef<HTMLUListElement>(null);

    const endAdornmentContainerClasses =
      EndAdornmentComponent !== undefined
        ? clsx(
            "nid-input-container-with-end-icon",
            (() => {
              switch (status) {
                case "error":
                  return "nid-input-container-with-end-icon--error";
                default:
                  return "nid-input-container-with-end-icon--default";
              }
            })(),
          )
        : undefined;
    const nidContainerClasses = clsx(
      "nid-input-container",
      disabled ? "nid-input-container--disabled" : undefined,
      rest.readOnly ? "nid-input-container-readonly" : undefined,
      endAdornmentContainerClasses,
      containerClassName,
    );

    const nidClasses = clsx(
      "nid-input-field",
      status && endAdornmentContainerClasses === undefined
        ? `nid-input-field--${status}`
        : undefined,
      size ? `nid-input-field--${size}` : undefined,
      disabled ? "nid-input-field--disabled" : undefined,
      className,
    );

    const enableAutoComplete = React.useMemo(
      () => showAutoComplete && autoCompleteList && autoCompleteList.length > 0,
      [showAutoComplete, autoCompleteList, autoCompleteList?.length],
    );

    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLLIElement>) => {
        const inputElement = inputRef.current;
        if (inputElement) {
          inputElement.value = e.currentTarget.textContent ?? "";
          onSelectAutoComplete?.(inputElement.value);
        }
        setShowAutoComplete(false);
        setSelectedIndex(undefined);
      },
      [onSelectAutoComplete],
    );

    const handleInputOrFocus = React.useCallback(
      (
        e:
          | React.FormEvent<HTMLInputElement>
          | React.FocusEvent<HTMLInputElement>,
      ) => {
        setShowAutoComplete(true);
        setSelectedIndex(undefined);
        setInputText(e.currentTarget.value);
      },
      [],
    );

    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (autoCompleteList === undefined) {
          return;
        }

        if (
          !showAutoComplete &&
          (e.key === "ArrowDown" || e.key === "ArrowUp")
        ) {
          setShowAutoComplete(true);
        }

        if (!enableAutoComplete) {
          return;
        }

        if (e.key === "ArrowDown") {
          const targetIndex =
            selectedIndex === undefined ? 0 : selectedIndex + 1;
          if (targetIndex < autoCompleteList.length) {
            autoCompleteRef.current?.children[targetIndex]?.scrollIntoView({
              block: "nearest",
              inline: "nearest",
            });
            setSelectedIndex(targetIndex);
            e.currentTarget.value = autoCompleteList[targetIndex];
          } else {
            setSelectedIndex(undefined);
            e.currentTarget.value = inputText;
          }
        }

        if (e.key === "ArrowUp") {
          const targetIndex =
            selectedIndex === undefined
              ? autoCompleteList.length - 1
              : selectedIndex - 1;

          if (targetIndex >= 0) {
            autoCompleteRef.current?.children[targetIndex]?.scrollIntoView({
              block: "nearest",
              inline: "nearest",
            });
            setSelectedIndex(targetIndex);
            e.currentTarget.value = autoCompleteList[targetIndex];
          } else {
            setSelectedIndex(undefined);
            e.currentTarget.value = inputText;
          }
        }

        if (e.key === "Enter") {
          e.currentTarget.value =
            selectedIndex !== undefined
              ? autoCompleteList[selectedIndex]
              : inputText;
          setShowAutoComplete(false);
          setSelectedIndex(undefined);
          onSelectAutoComplete?.(e.currentTarget.value);
          e.preventDefault();
        }

        if (e.key === "Escape") {
          setShowAutoComplete(false);
          setSelectedIndex(undefined);
        }
      },
      [
        selectedIndex,
        inputText,
        autoCompleteList,
        onSelectAutoComplete,
        enableAutoComplete,
      ],
    );

    // 域外クリックでサジェストを閉じる動作
    // BlurイベントではSafariで正常に動作しない場合があるためdocumentのclickイベントで処理
    const closeAutoComplete = (e: MouseEvent) => {
      if (autoCompleteRef.current?.contains(e.target as Node)) {
        return;
      }

      if (e.target === inputRef.current) {
        return;
      }

      setShowAutoComplete(false);
      setSelectedIndex(undefined);
      document.removeEventListener("click", closeAutoComplete);
    };

    React.useEffect(() => {
      enableAutoComplete
        ? document.addEventListener("click", closeAutoComplete)
        : document.removeEventListener("click", closeAutoComplete);
    }, [enableAutoComplete]);

    return (
      <Box className={nidContainerClasses} aria-busy={Boolean(loading)}>
        {StartAdornmentComponent && <StartAdornmentComponent />}
        <input
          className={nidClasses}
          disabled={disabled}
          ref={inputRef}
          onKeyDown={handleKeyDown}
          onFocus={handleInputOrFocus}
          onInput={handleInputOrFocus}
          {...rest}
        />
        {enableAutoComplete && autoCompleteList ? (
          <Box className={styles["autocomplete-container"]}>
            <ul ref={autoCompleteRef}>
              {autoCompleteList.map((v, i) => (
                // biome-ignore lint/a11y/useKeyWithClickEvents: サジェスト候補リストはフォーカスを取らないのでキーボード操作はinput要素で対応
                <li
                  key={v}
                  value={v}
                  className={
                    styles["autocomplete-option"] +
                    (selectedIndex === i
                      ? ` ${styles["autocomplete-option-active"]}`
                      : "")
                  }
                  onClick={handleClick}
                  onMouseEnter={() => setSelectedIndex(i)}
                >
                  {v}
                </li>
              ))}
            </ul>
          </Box>
        ) : undefined}
        {EndAdornmentComponent && (
          <Box className="nid-input-field-end-icon">
            <EndAdornmentComponent />
          </Box>
        )}
      </Box>
    );
  },
);

InputField.displayName = DISPLAY_NAME;
const Root = InputField;
export { InputField, Root };
export type { InputFieldProps };
export default InputField;
