import axios from "axios";
import { ACCOUNT_API_EXTERNAL_COMPANY_SUGGESTIONS } from "./apiPaths";

export type CompanySuggestionResponse = {
  hits: { main_name: string; houjin_code: number; official_name: string }[];
  total: number;
};

const FILTER_KEYWORD =
  "(is_listed:true is_etf:false) OR listed_tentou_joutai:>0";
const FIELDS = "main_name,houjin_code,official_name";

export const getCompanySuggestions = async (query: string) => {
  return axios.get<CompanySuggestionResponse>(
    ACCOUNT_API_EXTERNAL_COMPANY_SUGGESTIONS,
    {
      params: { q: query, filter_keyword: FILTER_KEYWORD, fields: FIELDS },
    },
  );
};

export const CompanySuggestionResourceKey = import.meta.env
  .VITE_API_EXTERNAL_COMPANY_SUGGESTIONS;
