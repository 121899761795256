import { useState } from "react";

import useSWRImmutable from "swr/immutable";
import {
  type JobInfoResponse,
  getAddress,
  getCompanyTypes,
  getCountries,
  getJobInfo,
} from "../api/account";

type Item = { name: string; code: string };

export type Address = Item;
export type Country = Item;
export type CompanyType = Item;

export type JobInfoMaster = {
  occupationNo: (Item & { requireDetail: boolean })[];
  businessNo: Item[];
  jobNo: Item[];
  positionNo: Item[];
  employeesNo: Item[];
  incomeNo: Item[];
};

export const useJobInfoMaster = () => {
  const { data, error } = useSWRImmutable(
    "/account/master/job-info",
    getJobInfo,
  );
  const toItem = (
    list: {
      label: string;
      code: string;
    }[],
  ) =>
    list.map((v) => {
      return { code: v.code, name: v.label };
    }) ?? [];

  const toOccupationNoItem = (list: JobInfoResponse["occupation_no"]) =>
    list.map((v) => {
      return {
        code: v.code,
        name: v.label,
        requireDetail: v.require_detail,
      };
    }) ?? [];

  if (error) {
    return { status: "error" } as const;
  }

  if (!data) {
    return { status: "loading" } as const;
  }

  return {
    status: "ok",
    occupationNo: toOccupationNoItem(data.data.occupation_no),
    businessNo: toItem(data.data.business_no),
    jobNo: toItem(data.data.job_no),
    positionNo: toItem(data.data.position_no),
    employeesNo: toItem(data.data.employees_no),
    incomeNo: toItem(data.data.income_no),
  } as const;
};

export const useCountriesMaster = () => {
  const { data, error } = useSWRImmutable(
    "/account/master/countries",
    getCountries,
  );
  if (error) {
    return { status: "error" } as const;
  }

  if (!data) {
    return { status: "loading" } as const;
  }
  const foreignCountries =
    data?.data?.countries?.map((v) => {
      return { code: v.code, name: v.label };
    }) ?? [];
  return {
    status: "ok",
    countries: [{ code: "000", name: "日本" }, ...foreignCountries],
  } as const;
};

export const useAddressMaster = (
  defaultZipCode: string | undefined,
): {
  refreshAddress: (zipCode: string | undefined) => void;
  address: Address[];
} => {
  const [zipCode, setZipCode] = useState<string | undefined>(defaultZipCode);
  const { data } = useSWRImmutable(
    `/account/master/address?zip_code=${zipCode}`,
    () => (zipCode ? getAddress(zipCode) : undefined),
  );

  const refreshAddress = (zipCode: string | undefined) => {
    setZipCode(zipCode);
  };

  const address =
    data?.data?.address?.map((v) => {
      return { code: v.code, name: v.label };
    }) ?? [];

  return {
    refreshAddress,
    address,
  };
};

export const useCompanyTypeMaster = () => {
  const { data, error } = useSWRImmutable(
    "/account/master/company-type",
    getCompanyTypes,
  );

  if (error) {
    return { status: "error" } as const;
  }

  if (!data) {
    return { status: "loading" } as const;
  }

  const companyType =
    data?.data?.company_types?.map((item) => {
      return {
        name: item.label,
        code: item.code,
      };
    }) ?? [];

  return {
    status: "ok",
    companyTypes: companyType,
  } as const;
};
