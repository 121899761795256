import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_REGISTERED_RELYING_PARTY } from "./apiPaths";

export interface RegisteredRelyingPartyResponse {
  client_id: string;
  service_name: string;
  logo?: string;
  description: string;
  links: {
    label: string;
    url: string;
  }[];
}

const getRegisteredRelyingPartyUrl = (client_id: string) => {
  const baseUrl = ACCOUNT_API_REGISTERED_RELYING_PARTY;
  return baseUrl.replace("<client_id>", client_id);
};

export const getRegisteredRelyingParty: (
  clientId: string,
) => Promise<AxiosResponse<RegisteredRelyingPartyResponse>> = async (
  clientId,
) => {
  return axios.get(getRegisteredRelyingPartyUrl(clientId), {
    withCredentials: true,
  });
};
