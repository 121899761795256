import * as React from "react";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Text";

const TEXT_NODES = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "p",
  "span",
  "div",
  "a",
  "label",
  "legend",
] as const;

type TextType = (typeof TEXT_NODES)[number];
type TextElement = React.ElementRef<TextType>;
type TextWithRefProps = React.ComponentPropsWithRef<TextType>;

type TextProps = TextWithRefProps &
  React.HTMLAttributes<HTMLElement> & {
    as?: React.ElementType;
    children?: React.ReactNode;
  };

const useTextState = (props: TextProps, ref: React.Ref<HTMLElement>) => {
  // as is not a valid prop for the DOM element

  const { as, ...rest } = props;
  return {
    ...rest,
    ...ref,
  };
};

/**
 * Box component
 */
const Text = React.forwardRef<TextElement, TextProps>((props, forwardedRef) => {
  const state = useTextState(props, forwardedRef);
  const Component = props.as || "div";

  return (
    <Component {...state} ref={forwardedRef}>
      {props.children}
    </Component>
  );
}) as React.ForwardRefExoticComponent<TextProps>;

Text.displayName = DISPLAY_NAME;

const Root = Text;

export { Text, Root };
export type { TextProps, TextElement };
