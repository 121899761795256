import axios, { type AxiosResponse } from "axios";
import { PROMOTION_CAMPAIGN_CANCEL_POPUP } from "./apiPaths";

type SegmentInfo = {
  segment_id: string;
  image_url: string;
  link_url: string;
};

export interface CampaignCancelPagePopupIdrpResponse {
  segment_info: SegmentInfo[];
}

export const getCampaignCancelPagePopupIdrp: (
  clientId: string,
  fileName: string,
) => Promise<AxiosResponse<CampaignCancelPagePopupIdrpResponse>> = async (
  clientId,
  fileName,
) => {
  const url = `${PROMOTION_CAMPAIGN_CANCEL_POPUP}/${clientId}/${fileName}`;
  return axios.get(url);
};
