import type React from "react";

import { Link as RouterLink } from "react-router-dom";

import Button, { type ButtonBaseProps } from "../Buttons/Button";

export type LinkButtonProps = {
  to?: string;
  href?: string;
  newTab?: boolean;
  children: React.ReactNode;
} & ButtonBaseProps;

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { to, href, newTab, children, ...buttonProps } = props;

  const linkProps = newTab
    ? { target: "_blank", rel: "noopener noreferrer" }
    : { rel: "noreferrer" };

  const inner = (
    <Button as="span" {...buttonProps}>
      {children}
    </Button>
  );
  return to ? (
    <RouterLink to={to} className="nid-link-button" {...linkProps}>
      {inner}
    </RouterLink>
  ) : (
    <a href={href} className="nid-link-button" {...linkProps}>
      {inner}
    </a>
  );
};
