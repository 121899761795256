import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_PAYMENT_HISTORY_DETAIL } from "./apiPaths";

export type PaymentTypeName = "サービス利用料" | "割引違約金";
export type ChangeTypeName =
  | "補正"
  | "本紙とのセット割"
  | "読者割"
  | "クーポン割引";

// 請求額変更
type PaymentDetailChange = {
  change_type: "1" | "2" | "3" | "4";
  change_type_name: ChangeTypeName;
  change_order: number;
  change_amount: number;
};

// 支払金額明細
type PaymentDetail = {
  price: number;
  changes?: PaymentDetailChange[];
};

export type Payment = {
  encrypted_payment_no: string;
  payment_type: "1" | "2";
  payment_type_name: PaymentTypeName;
  payment_history_display_string: string;
  payment_card_type: "1" | "2" | "3" | "4";
  sales_date: string;
  amount: number;
  tax_rate?: number;
  tax_amount?: number;
  payment_detail?: PaymentDetail;
  company_name: string;
  invoice_registration_no: string;
  service_charges_type: "1" | "2" | "3";
  service_id: string;
  service_name: string;
  plan_id?: string;
  plan_name?: string;
};

export type PaymentHistoryDetailResponse = {
  payments: Payment[];
};

export const getPaymentHistoryDetailUrl = (payment_id: string) => {
  const baseUrl = ACCOUNT_API_PAYMENT_HISTORY_DETAIL;
  return baseUrl.replace("<payment_id>", payment_id);
};

export const getPaymentHistoryDetail: (
  paymentId: string,
) => Promise<AxiosResponse<PaymentHistoryDetailResponse>> = async (
  paymentId,
) => {
  return axios.get(getPaymentHistoryDetailUrl(paymentId), {
    withCredentials: true,
  });
};
