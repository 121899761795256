import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_MFA_SETUP_EMAIL_START } from "./apiPaths";

const url = ACCOUNT_API_MFA_SETUP_EMAIL_START;

export type mfaSetupEmailStartRequest = {
  email?: string;
};

export type MfaSetupEmailStartResponse = {
  mfa_setup_token: string;
};

export const postMfaSetupEmailStart = (
  body: mfaSetupEmailStartRequest,
): Promise<AxiosResponse<MfaSetupEmailStartResponse>> => {
  return axios.post(url, body, {
    withCredentials: true,
  });
};
