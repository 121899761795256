import type React from "react";

import { Box } from "../../../foundations/Box";

export const ArrowRightIcon: React.FC<React.ComponentProps<"svg">> = (
  props,
) => (
  <Box className="nid-icon-container">
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Icon of ArrowRight"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.969727 2.03039L2.03039 0.969727L7.06072 6.00006L2.03039 11.0304L0.969727 9.96973L4.9394 6.00006L0.969727 2.03039Z"
        fill="#757575"
      />
    </svg>
  </Box>
);
