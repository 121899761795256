import { Box, SkeletonText } from "nikkei-ui";

import styles from "./SkeletonLoader.module.css";

export const ContentSkeletonLoader: React.FC = () => (
  <Box as="section" className="nid-section-transparent nid-section-overflow">
    <Box className="nid-section-inner-gap0">
      <SkeletonText size="tiny" lineCount={2} lineGap={"16px"} />
      <Box className={styles.pageLoaderContentHalf}>
        <SkeletonText size="tiny" lineCount={1} lineGap={"16px"} />
      </Box>
    </Box>
  </Box>
);

export default ContentSkeletonLoader;
