import axios from "axios";
import { ACCOUNT_API_PASSKEYS } from "./apiPaths";

type Passkey = {
  id: string;
  name?: string;
  backup_eligible: boolean;
  created_at: number;
  last_authenticated_at: number;
  aaguid: string;
};

export type PasskeysResponse = {
  passkeys: Passkey[];
  passkeys_creation_limit: number;
};

export const getPasskeys = async () => {
  return axios.get<PasskeysResponse>(ACCOUNT_API_PASSKEYS, {
    withCredentials: true,
  });
};
