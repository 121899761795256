import * as React from "react";

import { clsx } from "clsx";

import { Text as FoundationText } from "../../foundations/Text";

import type { TextProps } from "../../foundations/Text";

const DISPLAY_NAME = "Nid.Typography";

type VariantType =
  | "headline"
  | "body1"
  | "body2"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "subtitle3"
  | "small"
  | "tiny"
  | string;

type TypographyColor =
  | "success"
  | "error"
  | "link"
  | "accent"
  | "disabled"
  | "section-title"
  | undefined;

type TypographyElement = React.ElementRef<typeof FoundationText>;
type TypographyProps = TextProps & {
  variant?: VariantType;
  bold?: boolean;
  decoration?: "underline" | "line-through" | "none";
  center?: boolean;
  color?: TypographyColor;
};

const useTypographyState = (props: TypographyProps) => {
  const { variant, bold, decoration, color, className, ...rest } = props;
  return { ...rest };
};

/**
 * Typography component
 */
const Typography = React.forwardRef<TypographyElement, TypographyProps>(
  (props, forwardedRef) => {
    const root = "nid-typography";
    const variant = props.variant || "body1";
    const center = props.center ?? false;
    const variantClass = `nid-typography-${variant}`;

    const nidClasses = clsx(
      root,
      variantClass,
      {
        "nid-typography--bold": props.bold,
        "nid-typography--underline": props.decoration === "underline",
        "nid-typography--line-through": props.decoration === "line-through",
        "nid-typography--color-success": props.color === "success",
        "nid-typography--color-error": props.color === "error",
        "nid-typography--color-link": props.color === "link",
        "nid-typography--color-accent": props.color === "accent",
        "nid-typography--color-disabled": props.color === "disabled",
        "nid-typography--color-section-title": props.color === "section-title",
        "nid-typography--vertical-center": center,
      },
      props.className,
    );
    const state = useTypographyState(props);

    return (
      <FoundationText ref={forwardedRef} className={nidClasses} {...state} />
    );
  },
) as React.ForwardRefExoticComponent<TypographyProps>;

Typography.displayName = DISPLAY_NAME;

const Root = Typography;

export { Typography, Root };
export type { TypographyProps, TypographyColor };
export default Typography;
