import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";

type WebAuthenticationAPIGetRequest = {
  publicKey: PublicKeyCredentialRequestOptions;
  signal?: AbortSignal;
  mediation?: CredentialMediationRequirement;
};

type WebAuthenticationAPICreateRequest = {
  publicKey: PublicKeyCredentialCreationOptions;
  signal?: AbortSignal;
};

type WebAuthenticationAPI = {
  getPublicKeyCredential: (
    options: WebAuthenticationAPIGetRequest,
  ) => Promise<PublicKeyCredential | null>;

  createPublicKeyCredential: (
    options: WebAuthenticationAPICreateRequest,
  ) => Promise<PublicKeyCredential | null>;

  isConditionalMediationAvailable: boolean | undefined;
};

export const useWebAuthn = (): WebAuthenticationAPI => {
  const { data: isConditionalMediationAvailable } = useSWRImmutable(
    "/PublicKeyCredential/isConditionalMediationAvailable",
    async () => PublicKeyCredential?.isConditionalMediationAvailable?.(),
  );
  const getPublicKeyCredential = useCallback(
    async (
      request: WebAuthenticationAPIGetRequest,
    ): Promise<PublicKeyCredential | null> => {
      const credential = await navigator.credentials.get({
        signal: request.signal,
        mediation: request.mediation,
        publicKey: request.publicKey,
      });

      return credential !== null ? (credential as PublicKeyCredential) : null;
    },
    [],
  );

  const createPublicKeyCredential = useCallback(
    async (
      request: WebAuthenticationAPICreateRequest,
    ): Promise<PublicKeyCredential | null> => {
      const credential = await navigator.credentials.create({
        signal: request.signal,
        publicKey: request.publicKey,
      });

      return credential !== null ? (credential as PublicKeyCredential) : null;
    },
    [],
  );

  return {
    isConditionalMediationAvailable,
    getPublicKeyCredential,
    createPublicKeyCredential,
  };
};
