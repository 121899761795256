import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_MASTER_COMPANY_TYPE } from "./apiPaths";

export type CompanyTypeResponse = {
  company_types: {
    label: string;
    code: string;
  }[];
};

export const getCompanyTypes = async (): Promise<
  AxiosResponse<CompanyTypeResponse>
> => {
  return axios.get(ACCOUNT_API_MASTER_COMPANY_TYPE, {
    withCredentials: true,
  });
};
