import { type Score, zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import type { PasswordStrengthCheckerProps } from "nikkei-ui";

(async () => {
  const common = await import("@zxcvbn-ts/language-common");
  zxcvbnOptions.setOptions({
    graphs: common.adjacencyGraphs,
    dictionary: common.dictionary,
  });
})();

type PasswordStrengthCheckerMessages = {
  veryWeak: string;
  weak: string;
  normal: string;
  strong: string;
};

export const usePasswordStrengthChecker = (
  password: string,
  messages: PasswordStrengthCheckerMessages,
): {
  score: PasswordStrengthCheckerProps["score"];
  message: string;
} => {
  // 日本人のパスワードランキング2021 を参考にした
  // https://lms.shc.kanagawa.jp/pluginfile.php/66/mod_resource/content/2/WhitePaper_CSA-JP_202202.pdf
  const passwordScore: Score = zxcvbn(password, [
    "nikkei",
    "shinbun",
    "denshiban",
    "4946",
    "sakura",
    "dropbox",
    "takahiro",
    "maimai",
    "daisuki",
    "hoge",
    "banana",
    "doraemon",
    "arashi",
    "himawari",
    "samurai",
    "onepiece",
    "radwimps",
    "gundam",
    "neko",
  ]).score;

  const message = () => {
    if (password === "") {
      return "";
    }
    switch (passwordScore) {
      case 0:
        return messages.veryWeak;
      case 1:
        return messages.veryWeak;
      case 2:
        return messages.weak;
      case 3:
        return messages.normal;
      case 4:
        return messages.strong;
      default:
        // ここには来ない
        return messages.strong;
    }
  };

  return {
    score: password === "" ? "empty" : passwordScore,
    message: message(),
  };
};
