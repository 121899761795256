import type React from "react";

import { Box } from "../../../foundations/Box";

type IconProps = React.ComponentProps<"svg"> & {
  className?: string;
  width?: number;
  height?: number;
};

export const CloseIcon: React.FC<IconProps> = (props) => (
  <Box className={`nid-icon-container-large ${props.className}`}>
    <svg
      width={props.width || 24}
      height={props.height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Close Icon"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7785 5.63594L18.3643 4.22173L12 10.586L5.63574 4.22173L4.22153 5.63594L10.5858 12.0002L4.22212 18.3639L5.63634 19.7781L12 13.4144L18.3637 19.7781L19.7779 18.3639L13.4142 12.0002L19.7785 5.63594Z"
        fill={props.fill || "#003E70"}
      />
    </svg>
  </Box>
);
