import type React from "react";

export const ExclamationIcon: React.FC<React.ComponentProps<"svg">> = (
  props,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width="20"
    height="20"
    aria-label="Icon of Exclamation"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.257 3.0991C9.022 1.7391 10.979 1.7391 11.743 3.0991L17.323 13.0191C18.073 14.3531 17.11 15.9991 15.581 15.9991H4.42C2.89 15.9991 1.927 14.3531 2.677 13.0191L8.257 3.0991ZM11 13.0001C11 13.2653 10.8946 13.5197 10.7071 13.7072C10.5196 13.8947 10.2652 14.0001 10 14.0001C9.73478 14.0001 9.48043 13.8947 9.29289 13.7072C9.10536 13.5197 9 13.2653 9 13.0001C9 12.7349 9.10536 12.4805 9.29289 12.293C9.48043 12.1055 9.73478 12.0001 10 12.0001C10.2652 12.0001 10.5196 12.1055 10.7071 12.293C10.8946 12.4805 11 12.7349 11 13.0001ZM10 5.0001C9.73478 5.0001 9.48043 5.10546 9.29289 5.29299C9.10536 5.48053 9 5.73488 9 6.0001V9.0001C9 9.26532 9.10536 9.51967 9.29289 9.70721C9.48043 9.89474 9.73478 10.0001 10 10.0001C10.2652 10.0001 10.5196 9.89474 10.7071 9.70721C10.8946 9.51967 11 9.26532 11 9.0001V6.0001C11 5.73488 10.8946 5.48053 10.7071 5.29299C10.5196 5.10546 10.2652 5.0001 10 5.0001Z"
      fill="#D11100"
    />
  </svg>
);
