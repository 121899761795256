import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_MFA_SETUP_AUTHENTICATOR_CONFIRM } from "./apiPaths";

const url = ACCOUNT_API_MFA_SETUP_AUTHENTICATOR_CONFIRM;

export type mfaSetupAuthenticatorConfirmRequest = {
  mfa_setup_token: string;
  otp: string;
};

type PostMfaSetupAuthenticatorConfirmResponse = {
  backup_codes: string[];
};

type PostMfaSetupAuthenticatorConfirmErrorResponse =
  | "invalid_otp"
  | "invalid_request"
  | "invalid_token";

export type MfaSetupAuthenticatorConfirmErrorResponse = {
  error: PostMfaSetupAuthenticatorConfirmErrorResponse;
};

export const postMfaSetupAuthenticatorConfirm = (
  body: mfaSetupAuthenticatorConfirmRequest,
): Promise<AxiosResponse<PostMfaSetupAuthenticatorConfirmResponse>> => {
  return axios.post(url, body, {
    withCredentials: true,
  });
};
