import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_MFA_STATUS } from "./apiPaths";

export interface MfaStatusResponse {
  mfa_status: "authenticator_app_code" | "email_code" | "none";
  email?: string;
  email_same_as_login_id?: boolean;
}

export const getMfaStatus: () => Promise<AxiosResponse<MfaStatusResponse>> =
  async () => {
    return axios.get(ACCOUNT_API_MFA_STATUS, {
      withCredentials: true,
    });
  };
