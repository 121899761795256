import type React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";

const DISPLAY_NAME = "Nid.ListItem";

type ListItemProps = {
  className?: string;
  children: React.ReactNode;
};

const ListItem = ({ children, className, ...props }: ListItemProps) => {
  const nidClass = clsx("nid-list-item", className);

  return (
    <Box as="li" className={nidClass} {...props}>
      {children}
    </Box>
  );
};

ListItem.displayName = DISPLAY_NAME;

export { ListItem };
export type { ListItemProps };
export default ListItem;
