import * as React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";
import { Text } from "../../../foundations/Text";
import type { FormValidationStatus } from "../../../utils/types";

import { DropdownIcon } from "../../Icons/DropdownIcon/DropdownIcon";

const DISPLAY_NAME = "Nid.Select";

type OptionItem = {
  label: string;
  value: string | number;
};

type SelectProps = {
  options: OptionItem[];
  name: string;
  default?: boolean;
  disabled?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  label?: string;
  labelPosition?: "horizontal" | "vertical";
  containerclassname?: string;
  labelClassName?: string;
  className?: string;
  status?: FormValidationStatus;
  onChange?: (value: React.ChangeEvent<HTMLSelectElement>) => void;
} & React.ComponentPropsWithRef<"select">;
const useSelectState = (props: SelectProps) => {
  const { className, options, status, labelPosition, ...rest } = props;
  return { ...rest };
};

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, forwardedRef) => {
    const {
      disabled,
      label,
      labelPosition,
      className,
      containerclassname,
      labelClassName,
      status,
    } = props;
    const nidContainerClasses = clsx(
      "nid-form-container",
      disabled ? "nid-form-container-disabled" : undefined,
      status ? `nid-form-container-${status}` : undefined,
      labelPosition === "vertical" ? "nid-form-container-vertical" : undefined,
      labelPosition === "horizontal"
        ? "nid-form-container-horizontal"
        : undefined,
      containerclassname,
    );

    const nidLabelClasses = clsx(
      "nid-label",
      disabled ? "nid-form-label-disabled" : undefined,
      status ? `nid-form-label--color-${status}` : undefined,
      labelClassName,
    );
    const nidClasses = clsx(
      "nid-select",
      {
        "nid-select-disabled": props.disabled,
      },
      status ? `nid-select-${status}` : undefined,
    );

    const nidSelectClasses = className;

    const state = useSelectState(props);

    return (
      <Box className={nidContainerClasses}>
        {label ? (
          <Text as="label" className={nidLabelClasses}>
            <Text as="span" className="nid-label-text">
              {label}
            </Text>
            <Box className="nid-form-field-container">
              <Box className={nidClasses} aria-disabled={props.disabled}>
                <select
                  disabled={props.disabled}
                  id={props.name}
                  defaultValue={props.defaultValue}
                  ref={forwardedRef}
                  className={nidSelectClasses}
                  {...state}
                  onChange={(e) => {
                    if (props.onChange) props.onChange(e);
                  }}
                >
                  {props.options.map((item: OptionItem, index) => {
                    return (
                      <option key={`${props.name}-${index}`} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
                <Box className="nid-select-icon">
                  <DropdownIcon />
                </Box>
              </Box>
            </Box>
          </Text>
        ) : (
          <Box className={nidClasses} aria-disabled={props.disabled}>
            <select
              disabled={props.disabled}
              id={props.name}
              defaultValue={props.defaultValue}
              ref={forwardedRef}
              {...state}
            >
              {props.options.map((item: OptionItem) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            <Box className="nid-select-icon">
              <DropdownIcon />
            </Box>
          </Box>
        )}
      </Box>
    );
  },
) as React.ForwardRefExoticComponent<SelectProps>;

Select.displayName = DISPLAY_NAME;
const Root = Select;

export { Select, Root };
export type { SelectProps, OptionItem };
export default Select;
