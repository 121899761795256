import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_WITHDRAW } from "./apiPaths";

export type WithdrawResponse = {
  delete_sso_cookies_url: string;
};

export const postWithdraw = (): Promise<AxiosResponse<WithdrawResponse>> => {
  return axios.post(ACCOUNT_API_WITHDRAW, undefined, {
    withCredentials: true,
  });
};
