export type Base64urlString = string;

export const encodeBase64Url = (buf: ArrayBuffer): string => {
  return btoa(String.fromCharCode(...new Uint8Array(buf)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};

export const encodeStringToBase64Url = (str: string): string => {
  return encodeBase64Url(new TextEncoder().encode(str).buffer);
};

export const decodeBase64Url = (base64Url: string): ArrayBuffer => {
  const base64 = base64Url
    .replace(/-/g, "+")
    .replace(/_/g, "/")
    .padEnd(base64Url.length + ((4 - (base64Url.length % 4)) % 4), "=");

  const binaryString = atob(base64);

  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  return byteArray.buffer;
};
