import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_MFA_BACKUP_CODE } from "./apiPaths";

const url = ACCOUNT_API_MFA_BACKUP_CODE;

export type GetMfaBackupCodeResponse = {
  backup_codes: string[];
};

export type GetMfaBackupCodeErrorResponse = {
  error: "invalid_request";
};

export const getMfaBackupCode: () => Promise<
  AxiosResponse<GetMfaBackupCodeResponse>
> = async () => {
  return axios.get(url, {
    withCredentials: true,
  });
};
