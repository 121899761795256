import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_LOGIN_ID_CHANGE_START } from "./apiPaths";

const url = ACCOUNT_API_LOGIN_ID_CHANGE_START;

export type ChangeLoginIdStartRequest = {
  login_id: string;
};

export type ChangeLoginIdStartErrorCode =
  | "invalid_email"
  | "already_registered";

export type EmailChangeStartErrorResponse = {
  error: ChangeLoginIdStartErrorCode;
};

export const postChangeLoginIdStart = (
  body: ChangeLoginIdStartRequest,
): Promise<AxiosResponse> => {
  return axios.post(url, body, {
    withCredentials: true,
  });
};
