import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_PASSWORD } from "./apiPaths";

const url = ACCOUNT_API_PASSWORD;

export type PasswordChangeRequest = {
  new_password: string;
  redirect?: "UTILITIES" | "ACCOUNT";
};

export type PasswordChangeResponse = {
  delete_sso_cookies_url: string;
};

export type PasswordErrorResponse = {
  error?: "invalid_request" | undefined;
};

export const postChangePassword = (
  body: PasswordChangeRequest,
): Promise<AxiosResponse<PasswordChangeResponse>> => {
  return axios.patch(url, body, {
    withCredentials: true,
  });
};
