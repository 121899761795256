import { useEffect, useState } from "react";

import useSWRImmutable from "swr/immutable";
import { useDebouncedCallback } from "use-debounce";
import {
  CompanySuggestionResourceKey,
  getCompanySuggestions,
} from "../api/account";
import type { CompanyType } from "./useMasterData";

const MINIMUM_QUERY_LENGTH = 2;
const COMPANY_PATTERNS = [
  ["株式会社", "(株)", "（株）", "株・", "・株"],
  ["有限会社", "(有)", "（有）", "有・", "・有"],
  ["社団法人"],
  ["財団法人"],
  ["医療法人"],
  ["学校法人"],
  ["合同会社"],
] as const;

const getTypeCodeFront = (
  v: string,
  companyTypeCode: Record<string, string>,
): string => {
  const match = COMPANY_PATTERNS.find(
    (p) => p.find((q) => v.startsWith(q)) !== undefined,
  );
  return match ? (companyTypeCode[match[0]] ?? "0") : "0";
};

const getTypeCodeBack = (
  v: string,
  companyTypeCode: Record<string, string>,
): string => {
  const match = COMPANY_PATTERNS.find(
    (p) => p.find((q) => v.endsWith(q)) !== undefined,
  );
  return match ? (companyTypeCode[match[0]] ?? "0") : "0";
};

type AuthCompleteCompany = {
  companyName: string;
  companyNumber: string;
  typeCodeFront: string;
  typeCodeBack: string;
};

export const useAutoCompleteCompanyName = (companyTypes: CompanyType[]) => {
  const [query, setQuery] = useState<string>("");
  const [companies, setCompanies] = useState<AuthCompleteCompany[]>([]);
  const searchCompanyNames = useDebouncedCallback(
    (query: string) => setQuery(query),
    300,
  );
  const clearCompanyNames = () => {
    setQuery("");
  };

  const companyTypeCodeList = companyTypes.reduce(
    (acc, curr) => {
      acc[curr.name] = curr.code;
      return acc;
    },
    {} as Record<string, string>,
  );

  const { data } = useSWRImmutable(
    `${CompanySuggestionResourceKey}?q=${query}`,
    () =>
      query.length >= MINIMUM_QUERY_LENGTH
        ? getCompanySuggestions(query)
        : undefined,
  );

  useEffect(() => {
    if (query.length >= MINIMUM_QUERY_LENGTH && !data) return;
    const newCompanies =
      data?.data.hits.map((v) => {
        return {
          companyName: v.main_name,
          companyNumber: String(v.houjin_code),
          typeCodeFront: getTypeCodeFront(v.official_name, companyTypeCodeList),
          typeCodeBack: getTypeCodeBack(v.official_name, companyTypeCodeList),
        };
      }) ?? [];
    setCompanies(newCompanies);
  }, [data]);

  return {
    companies,
    searchCompanyNames,
    clearCompanyNames,
  };
};
