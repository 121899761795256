import useSWR from "swr";
import { getLoginStatus } from "../api/account";

export const useUserStatus = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "/account/status",
    getLoginStatus,
  );

  if (error) return { status: "error", error, mutate } as const;
  if (!data || isLoading) return { status: "loading", error, mutate } as const;

  return {
    status: "ok",
    loginId: data.data.login_id || "",
    data: data.data,
    serverDate: new Date(data.headers.date),
    mutate,
  } as const;
};
