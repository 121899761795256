import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_MASTER_JOB_INFO } from "./apiPaths";

interface Item {
  label: string;
  code: string;
}

export interface JobInfoResponse {
  occupation_no: (Item & { require_detail: boolean })[];
  business_no: Item[];
  job_no: Item[];
  position_no: Item[];
  employees_no: Item[];
  income_no: Item[];
}

export const getJobInfo: () => Promise<AxiosResponse<JobInfoResponse>> =
  async () => {
    return axios.get(ACCOUNT_API_MASTER_JOB_INFO, {
      withCredentials: true,
    });
  };
