import type { AxiosResponse } from "axios";
import axios from "axios";
import { ACCOUNT_API_PASSKEYS } from "./apiPaths";

type DeletePasskeyRequest = {
  id: string;
};

export const deletePasskey = (
  body: DeletePasskeyRequest,
): Promise<AxiosResponse> => {
  const url = `${ACCOUNT_API_PASSKEYS}/${body.id}`;
  return axios.delete(url, {
    withCredentials: true,
  });
};
