import type { AxiosResponse } from "axios";
import axios from "axios";
import { ACCOUNT_API_PAYMENTS_CARDS } from "./apiPaths";

type DeleteCreditCardRequest = {
  card_id: string;
};

export type DeleteCreditCardErrorResponse = {
  error?: "service_exists";
};

export const deleteCreditCard = (
  body: DeleteCreditCardRequest,
): Promise<AxiosResponse> => {
  return axios.delete(ACCOUNT_API_PAYMENTS_CARDS, {
    data: body,
    withCredentials: true,
  });
};
