import * as React from "react";

import { clsx } from "clsx";

import { Text } from "../../../foundations/Text";

import type { TextProps } from "../../../foundations/Text";

const DISPLAY_NAME = "Nid.Form.Label";

export type VariantType = "headline" | "body1" | "body2" | "caption" | string;

type LabelElement = React.ElementRef<typeof Text>;
type LabelProps = TextProps & {
  variant?: VariantType;
  color?: "error";
};

const useLabelState = (props: LabelProps) => {
  const { variant, color, className, ...rest } = props;
  return { ...rest };
};

/**
 * Label component
 */
const Label = React.forwardRef<LabelElement, LabelProps>(
  (props, forwardedRef) => {
    const root = "nid-label";
    const variant = props.variant || "body1";
    const variantClass = `nid-label-${variant}`;

    const nidClasses = clsx(
      root,
      variantClass,
      {
        "nid-form-label--color-error": props.color === "error",
      },
      props.className,
    );

    const state = useLabelState(props);

    return (
      <Text as="label" ref={forwardedRef} className={nidClasses} {...state} />
    );
  },
) as React.ForwardRefExoticComponent<LabelProps>;

Label.displayName = DISPLAY_NAME;

const Root = Label;

export { Label, Root };
export type { LabelProps, LabelElement };
export default Label;
