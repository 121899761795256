const regexp = /^[\da-zA-Z!#$%&'()*+\-.,/:;<=>?@[\]\\^_`{|}"~ ]+$/g;

export const validatePassword = (
  password: string,
):
  | "ok"
  | "empty"
  | "too_short_password"
  | "too_long_password"
  | "invalid_format" => {
  if (password.length === 0) {
    return "empty";
  }
  if (password.length < 6) {
    return "too_short_password";
  }
  if (password.length > 200) {
    return "too_long_password";
  }
  if (!password.match(/^\S/)) {
    return "invalid_format";
  }
  if (!password.match(regexp)) {
    return "invalid_format";
  }

  return "ok";
};
