import type React from "react";

type NikkeiIDLogoProps = {
  width?: number;
};
/**
 * NikkeiIDLogo
 *
 * @example
 * ```ts
 *  <NikkeiIDLogo />
 * ```
 * @param __namedParameters
 * @returns JSX.Element
 * @category ReactComponents
 */
export const NikkeiIDLogo: React.FC<NikkeiIDLogoProps> = ({ width = 134 }) => (
  <svg
    role="img"
    aria-label="NIKKEI ID"
    width={width.toString()}
    viewBox="0 0 147 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <style>
      {".st0{fill:#0A3E6F;} enable-background:new 0 0 439.25 93.84;"}
    </style>
    <g clipPath="url(#clip0_7986_142290)">
      <path
        className="st0"
        d="M18.106 24L3.038 6.441V23.453H0V0H1.154L16.1 17.438V0.486H19.077V24H18.106Z"
        fill="currentColor"
      />
      <path
        className="st0"
        d="M29.8325 0.486084H26.8555V23.4531H29.8325V0.486084Z"
        fill="currentColor"
      />
      <path
        className="st0"
        d="M43.6244 11.9091L40.5864 15.1861V23.4491H37.6094V0.486084H40.5864V11.7271L50.6724 0.486084H53.8324V0.608084L45.6904 9.78608L54.4394 23.2711V23.4531H51.0984L43.6244 11.9091Z"
        fill="currentColor"
      />
      <path
        className="st0"
        d="M65.3158 11.9091L62.2778 15.1861V23.4491H59.3008V0.486084H62.2778V11.7271L72.4248 0.486084H75.5248V0.608084L67.3818 9.78608L76.1308 23.2751V23.4571H72.8498L65.3158 11.9091Z"
        fill="currentColor"
      />
      <path
        className="st0"
        d="M81.0547 0.486084H93.3277V2.97708H83.9707V10.2681H92.1127V12.7591H83.9707V20.9011H93.3277V23.4531H81.0547V0.486084Z"
        fill="currentColor"
      />
      <path
        className="st0"
        d="M103.231 0.486084H100.254V23.4531H103.231V0.486084Z"
        fill="currentColor"
      />
      <path
        className="st0"
        d="M120.426 0.486084H117.449V23.4531H120.426V0.486084Z"
        fill="currentColor"
      />
      <path
        className="st0"
        d="M130.389 2.97708V20.9011H132.88C139.08 20.9011 143.331 19.0171 143.331 12.1521C143.331 5.34708 139.078 3.03808 132.88 3.03808H130.389V2.97708ZM127.473 0.486084H133.124C140.84 0.486084 146.43 4.13208 146.43 12.1521C146.43 20.2331 140.84 23.4521 133.124 23.4521H127.473V0.486084Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7986_142290">
        <rect width="146.43" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
NikkeiIDLogo.displayName = "NikkeiIDLogo";

export const NikkeiLogo: React.FC<React.ComponentProps<"svg">> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="57"
    viewBox="0 0 439.25 93.84"
    xmlSpace="preserve"
    aria-label="Nikkei Logo"
    role="img"
    {...props}
  >
    <style>
      {".st0{fill:#0A3E6F;} enable-background:new 0 0 439.25 93.84;"}
    </style>
    <g>
      <polygon
        className="st0"
        points="89.12,0 72.82,60.82 52.77,0 25.15,0 0,93.84 22.82,93.84 22.92,93.84 22.94,93.84 40.39,28.66 61.81,93.84 86.87,93.84 86.89,93.84 112.04,0"
      />
      <polygon
        className="st0"
        points="416.33,0 391.18,93.84 414.1,93.84 439.25,0"
      />
      <polygon
        className="st0"
        points="125.77,0 100.62,93.84 123.54,93.84 148.69,0"
      />
      <polygon
        className="st0"
        points="382.35,55.31 387.03,37.86 347.98,37.86 353.02,19.07 396.76,19.07 401.87,0 335.21,0 310.62,91.75 292.53,43.42 285.43,43.42 285.41,43.42 328.33,0 301.28,0 261.22,39.84 271.9,0 248.98,0 224.25,92.26 205.98,43.42 198.87,43.42 198.86,43.42 241.78,0 214.72,0 174.66,39.84 185.34,0 162.42,0 137.27,93.84 137.74,93.84 160.03,93.84 160.19,93.84 173.7,43.42 181.81,43.42 198.31,93.84 246.59,93.84 246.75,93.84 260.26,43.42 268.37,43.42 284.87,93.84 377.36,93.84 382.47,74.8 338.08,74.8 343.3,55.31"
      />
    </g>
  </svg>
);
NikkeiLogo.displayName = "NikkeiLogo";
