import * as React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";
import { Text } from "../../../foundations/Text";
import type { FormValidationStatus } from "../../../utils/types";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Nid.Textarea";

type TextareaElement = React.ElementRef<"textarea">;
type TextareaWithRefProps = React.ComponentPropsWithRef<"textarea">;

type TextareaProps = TextareaWithRefProps &
  React.InputHTMLAttributes<TextareaElement> & {
    label?: string;
    labelPosition?: "horizontal" | "vertical";
    containerclassname?: string;
    labelClassName?: string;
    className?: string;
    status?: FormValidationStatus;
  };

const useTextareaState = (props: TextareaProps) => {
  const { label, labelPosition, status, ...rest } = props;

  return { ...rest };
};

/**
 * Textarea component
 */
const Textarea = React.forwardRef<TextareaElement, TextareaProps>(
  (
    {
      disabled,
      label,
      labelPosition,
      className,
      containerclassname,
      labelClassName,
      status,
      ...rest
    },
    forwardedRef,
  ) => {
    const nidContainerClasses = clsx(
      "nid-form-container",
      disabled ? "nid-form-container-disabled" : undefined,
      status ? `nid-form-container-${status}` : undefined,
      labelPosition === "vertical" ? "nid-form-container-vertical" : undefined,
      labelPosition === "horizontal"
        ? "nid-form-container-horizontal"
        : undefined,
      containerclassname,
    );

    const nidLabelClasses = clsx(
      "nid-label",
      disabled ? "nid-form-label-disabled" : undefined,
      rest.readOnly ? "nid-form-label-readonly" : undefined,
      status ? `nid-form-label--color-${status}` : undefined,
      labelClassName,
    );

    const nidClasses = clsx(
      "nid-textarea",
      disabled ? "nid-textarea-disabled" : undefined,
      rest.readOnly ? "nid-textarea-readonly" : undefined,
      status ? `nid-textarea-${status}` : undefined,
      className,
    );

    const state = useTextareaState(rest);

    return (
      <Box className={nidContainerClasses}>
        {label ? (
          <Text as="label" className={nidLabelClasses}>
            <Text as="span" className="nid-label-text">
              {label}
            </Text>
            <Box className="nid-form-field-container">
              <textarea
                className={nidClasses}
                ref={forwardedRef}
                disabled={disabled}
                {...state}
              />
            </Box>
          </Text>
        ) : (
          <textarea
            className={nidClasses}
            ref={forwardedRef}
            disabled={disabled}
            {...state}
          />
        )}
      </Box>
    );
  },
) as React.ForwardRefExoticComponent<TextareaProps>;

Textarea.displayName = DISPLAY_NAME;
const Root = Textarea;
export { Textarea, Root };
export type { TextareaProps };
export default Textarea;
