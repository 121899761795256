import * as React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";

import type { FormValidationStatus } from "../../../utils/types";

const DISPLAY_NAME = "Nid.Radio";

type InputWithRefProps = React.ComponentPropsWithRef<"input">;

type RadioProps = {
  label: string | undefined;
  name?: string;
  id?: string;
  value: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  className?: string;
  status?: FormValidationStatus;
} & InputWithRefProps;

const useRadioState = (props: RadioProps) => {
  const { label, className, id, status, ...rest } = props;
  return { ...rest };
};

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (props, forwardedRef) => {
    const nidClasses = clsx(props.className);
    const nidContainerClasses = clsx(
      "nid-radio",
      props.disabled && "nid-radio-disabled",
      props.status ? `nid-radio-${props.status}` : undefined,
    );
    const nidLabelClasses = clsx("nid-typography", "nid-radio-label");

    const state = useRadioState(props);
    const isDisabled = props.disabled || false;

    return (
      <fieldset disabled={isDisabled} className={nidContainerClasses}>
        <label>
          <Box as="span" className="nid-radio-radio">
            <input
              type="radio"
              className={nidClasses}
              ref={forwardedRef}
              {...state}
            />
          </Box>
          <Box as="span" className={nidLabelClasses}>
            {props.label}
          </Box>
        </label>
      </fieldset>
    );
  },
) as React.ForwardRefExoticComponent<RadioProps>;

Radio.displayName = DISPLAY_NAME;
const Root = Radio;

export { Radio, Root };
export type { RadioProps };
export default Radio;
