import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from "react";

declare global {
  interface WindowEventMap {
    "session-storage": CustomEvent;
  }
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

type RemoveValue = () => void;

/**
 * useSessionStorage
 * - セッションストレージを操作するためのカスタムフック
 * @param {string} key セッションストレージに保存するためのキー。
 * @param {T} initialValue セッションストレージに設定する初期値。
 * @returns {object} オブジェクト形式
 *  - {T} value 現在のストレージ値
 *  - {SetValue<T>} setValue その値を設定するための関数
 *  - {RemoveValue} removeValue セッションストレージからその値を削除するための関数
 */
export const useSessionStorage = <T>(
  key: string,
  initialValue: T,
): { value: T; setValue: SetValue<T>; removeValue: RemoveValue } => {
  const getValue = useCallback(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const value = window.sessionStorage.getItem(key);
      return value ? (JSON.parse(value) as T) : initialValue;
    } catch (_) {
      return initialValue;
    }
  }, [key]);

  const [storedValue, setStoredValue] = useState<T>(getValue);

  const setValue: SetValue<T> = (newValue) => {
    try {
      const newValueString = JSON.stringify(newValue);
      window.sessionStorage.setItem(key, newValueString);

      setStoredValue(newValue);
    } catch (error) {
      console.warn(`Error set session storage key ${key}:`, error);
    }
  };

  const removeValue = useCallback(() => {
    try {
      window.sessionStorage.removeItem(key);
      setStoredValue(initialValue);
    } catch (error) {
      console.warn(`Error remove session storage key ${key}:`, error);
    }
  }, [key, initialValue]);

  return { value: storedValue, setValue, removeValue };
};
