import React, { useRef, useState } from "react";

import { clsx } from "clsx";
import { Box, PinCode } from "nikkei-ui";

type Props = {
  onChange?: (newValue: string[]) => void;
  onFill?: (values: string[]) => void | Promise<void>;
  name?: string;
  valueLength: number;
  isLoading: boolean;
  testId?: string;
  className?: string;
  id?: string;
};

const regDigits = new RegExp(/^\d+$/);

const strToArray = (str: string, len: number) => {
  const ret: string[] = [];
  for (let i = 0; i < len; i++) {
    ret.push(i < str.length ? str.charAt(i) : "");
  }
  return ret;
};

export const PinCodeField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      onFill,
      valueLength,
      isLoading,
      testId = "pin-code-field",
      className,
      id = "pin-code-field",
    },
    _,
  ) => {
    const [values, setValues] = useState(strToArray("", valueLength));
    const inputs = [...Array(valueLength)].map(() => {
      return useRef<HTMLInputElement>(null);
    });
    const [isImeMode, setIsImeMode] = useState(false);

    const getNextValue = (index: number, input: string): string[] => {
      const nextValues = [...values];
      if (input === "") {
        nextValues[index] = "";
      } else {
        const to = Math.min(valueLength - index, input.length);
        for (let i = 0; i < to; i++) {
          nextValues[i + index] = input.charAt(i);
        }
      }
      return nextValues;
    };

    const setFocus = (index: number) => {
      inputs[Math.min(Math.max(0, index), valueLength - 1)].current?.focus();
    };

    const onKeyDown = (e: React.KeyboardEvent, index: number) => {
      if (isLoading) return;
      if (e.key === "Backspace") {
        const nextValues = getNextValue(index, "");
        handleChange(nextValues);
        setFocus(index - 1);
        e.preventDefault();
      } else if (e.key === "ArrowRight" || e.key === "ArrowDown") {
        setFocus(index + 1);
        e.preventDefault();
      } else if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
        setFocus(index - 1);
        e.preventDefault();
      } else if (isImeMode && regDigits.test(e.key)) {
        const nextValues = getNextValue(index, e.key);
        handleChange(nextValues);
        setFocus(index + 1);
        e.preventDefault();
      }
    };

    const onChangeDigit = (
      e: React.ChangeEvent<HTMLInputElement>,
      index: number,
    ) => {
      if (isLoading || isImeMode) return;
      if (!regDigits.test(e.target.value)) return;
      e.preventDefault();

      let input: string;

      if (values[index] && e.target.value.length === 2) {
        // 入力済みのフォームに入力された場合は上書きする
        input =
          values[index] === e.target.value.charAt(0)
            ? e.target.value.charAt(1)
            : e.target.value.charAt(0);
      } else {
        input = e.target.value;
      }

      const nextValues = getNextValue(index, input);
      handleChange(nextValues);
      setFocus(Math.min(valueLength - 1, index + input.length));
    };

    const onPaste = (e: React.ClipboardEvent, index: number) => {
      if (isLoading) return;
      const input = e.clipboardData.getData("text");
      if (!regDigits.test(input)) return;
      e.preventDefault();

      const nextValues = getNextValue(index, input);
      handleChange(nextValues);
      setFocus(Math.min(valueLength - 1, index + input.length));
    };

    const handleChange = (nextValues: string[]) => {
      setValues(nextValues);
      onChange?.(nextValues);
      if (nextValues.every((v) => v !== "") && onFill) {
        onFill(nextValues);
      }
    };

    const classNames = clsx("nid-pincode-group", className);

    return (
      <Box className={classNames}>
        {values.map((digit, index) => {
          return (
            <PinCode
              // biome-ignore lint/suspicious/noArrayIndexKey: Pin Codeは静的な順番のためindexをキーにしても問題ない
              key={index}
              data-testid={`${testId}-${index}`}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              pattern="\d{1}"
              disabled={isLoading}
              maxLength={valueLength}
              value={digit}
              autoFocus={index === 0}
              onKeyDown={(e) => onKeyDown(e, index)}
              onChange={(e) => onChangeDigit(e, index)}
              onPaste={(e) => onPaste(e, index)}
              onCompositionStart={(e) => {
                if (e.type === "compositionstart") {
                  setIsImeMode(true);
                }
              }}
              onCompositionEnd={(e) => {
                if (e.type === "compositionend") {
                  setIsImeMode(false);
                }
              }}
              ref={inputs[index]}
              id={`${id}-${index}`}
            />
          );
        })}
      </Box>
    );
  },
);

PinCodeField.displayName = "Nid.Form.PinCodeField";
