import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_MFA_SETUP_EMAIL_CONFIRM } from "./apiPaths";

const url = ACCOUNT_API_MFA_SETUP_EMAIL_CONFIRM;

export type mfaSetupEmailConfirmRequest = {
  mfa_setup_token: string;
  otp: string;
};

export type PostMfaSetupEmailConfirmResponse = {
  backup_codes: string[];
};

export type PostMfaSetupEmailConfirmErrorResponse =
  | "invalid_otp"
  | "invalid_request"
  | "invalid_token";

export type MfaSetupEmailConfirmErrorResponse = {
  error: PostMfaSetupEmailConfirmErrorResponse;
};

export const postMfaSetupEmailConfirm = (
  body: mfaSetupEmailConfirmRequest,
): Promise<AxiosResponse<PostMfaSetupEmailConfirmResponse>> => {
  return axios.post(url, body, {
    withCredentials: true,
  });
};
