import type React from "react";

import { Box } from "../../../foundations/Box";

export const ExternalLinkIcon: React.FC<React.ComponentProps<"svg">> = (
  props,
) => (
  <Box className="nid-icon-container">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Icon of ExternalLink"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3333 2.33333V1H15V5.66667H13.6667V3.27333L7.11334 9.82667L6.17334 8.88667L12.7267 2.33333H10.3333ZM3.33333 12.6667H12.6667V9H14V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.59333 14 2 13.4 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2H7V3.33333H3.33333V12.6667Z"
        fill="currentColor"
      />
    </svg>
  </Box>
);
