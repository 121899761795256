import axios from "axios";
import { ACCOUNT_API_MASTER_COUNTRIES } from "./apiPaths";

export type CountriesResponse = {
  countries: { label: string; code: string }[];
};

export const getCountries = async () => {
  return axios.get<CountriesResponse>(ACCOUNT_API_MASTER_COUNTRIES, {
    withCredentials: true,
  });
};
