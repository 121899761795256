import type React from "react";

import { CheckIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";

const DISPLAY_NAME = "Nid.Flash";

type FlashProps = {
  variant?: "success" | "danger" | "default";
  full?: boolean;
  position?: "center" | "left" | "right";
  className?: string;
  children: React.ReactElement;
};

/**
 * FlashMessage component
 */
const FlashMessage = (props: FlashProps) => {
  const {
    variant = "default",
    full = false,
    position = "center",
    className,
    children,
  } = props;
  const variantClass = `nid-message--${variant}`;
  const positionClass = position === "center" ? "" : `--${position}`;

  const nidClass = clsx(
    `nid-flash-message${positionClass}`,
    variantClass,
    {
      "w-full": full,
    },
    className,
  );

  return (
    <Box className={nidClass}>
      {variant === "success" && (
        <CheckIcon
          role="img"
          width="20px"
          height="20px"
          id="check-icon"
          data-testid="check-icon"
        />
      )}
      {variant === "danger" && (
        <ExclamationTriangleIcon
          role="img"
          width="20px"
          height="20px"
          id="ec-icon"
          data-testid="ec-icon"
        />
      )}
      {children}
    </Box>
  );
};

FlashMessage.displayName = DISPLAY_NAME;

const Root = FlashMessage;

export { FlashMessage, Root };
export type { FlashProps as FlashMessageProps };
export default FlashMessage;
