import type * as React from "react";

import { clsx } from "clsx";

import type { FormValidationStatus } from "../../../utils/types";
import { FormHelperText } from "../FormHelperText";
import { Label } from "../Label";

const DISPLAY_NAME = "Nid.FormControl";
export type LabelVariant = "head" | "float" | undefined;
export type FormControlProps = {
  id?: string;
  status: FormValidationStatus;
  required?: boolean;
  disabled?: boolean;
  hasValue: boolean;
  layout?: "horizontal" | "vertical";
  labelVariant: LabelVariant;
  className?: string;
  children: React.ReactNode;
};

const useControlState = (props: FormControlProps) => {
  const { labelVariant, layout, hasValue, status, children, ...rest } = props;
  return { ...rest };
};

const Control = (props: FormControlProps) => {
  const {
    id,
    status,
    disabled,
    required,
    layout,
    labelVariant,
    hasValue,
    children,
    className,
  } = props;
  const layoutVal = layout || "vertical";
  const layoutClass = `nid-form-container-${layoutVal}`;
  const labelVariantType = labelVariant || "head";
  const variantClass = `nid-input-control-${labelVariantType}`;

  const nidClasses = clsx(
    layoutClass,
    status ? `nid-form-field-container-${status}` : undefined,
    variantClass,
    hasValue && labelVariantType === "float"
      ? "nid-form-field-container-has-value"
      : undefined,
    className,
  );

  const state = useControlState(props);

  return (
    <fieldset
      id={id}
      disabled={disabled}
      aria-required={required}
      className={nidClasses}
      {...state}
    >
      {children}
    </fieldset>
  );
};

Control.displayName = DISPLAY_NAME;
const Root = Control;

const FormControl = Object.assign(Control, {
  Label,
  Validation: FormHelperText,
});

export { FormControl, Root };
export default FormControl;
