import type React from "react";

export const KeyIcon: React.FC<React.ComponentProps<"svg">> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    aria-label="Icon of Password"
    aria-hidden="true"
    {...props}
  >
    <g id="layer">
      <path
        d="M21,9.022H10.305C9.356,7.663,7.783,6.772,6,6.772c-2.899,0-5.25,2.35-5.25,5.25 c0,2.899,2.351,5.25,5.25,5.25c2.093,0,3.895-1.228,4.738-3H12.7l1.3-1.25l1.5,1.5l1.5-1.5l1.8,1.75h1.4l2.8-2.75L21,9.022z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <circle
        cx="4.5"
        cy="12.022"
        r="0.75"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <g id="frame">
      <rect
        x="0"
        y="0"
        width="24"
        height="24"
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
