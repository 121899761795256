export const useLeaveApp = () => {
  return {
    redirect: (location: string, replace?: boolean) => {
      if (replace === true) {
        history.replaceState(null, "", location);
      }
      window.location.href = location;
    },
  };
};
